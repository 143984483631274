/* eslint-disable import/prefer-default-export */
/* eslint-disable class-methods-use-this */
/* eslint-disable prettier/prettier */
import axios from 'axios';

import serviceConfig from '../ServiceConfig';
import { Decuius } from '../types/decuius';
import { PaginationResult } from 'types/paginationResult';
import { PaginationQuery } from 'types/paginationQuery';
import { Pratica } from 'types/pratica';
import { Transfer } from 'types/transfer';
import { DecuiusInfo } from 'types/decuiusInfo';
import { Allegato } from 'types/allegato';

export class DecuiusService {
    async GetAllDecuius(paginationQuery: PaginationQuery, filters: object): Promise<PaginationResult<Decuius>> {
        const result = await axios.post<PaginationResult<Decuius>>(`${serviceConfig.appServiceBaseUrl}/GetAllDecuius`, {
            ...paginationQuery,
            ...filters
        });
        return result.data;
    }

    async GetDecuiusToAssign(paginationQuery: PaginationQuery, filters: object): Promise<PaginationResult<Decuius>> {
        const result = await axios.post<PaginationResult<Decuius>>(`${serviceConfig.appServiceBaseUrl}/GetDecuiusToAssign`, {
            ...paginationQuery,
            ...filters
        });
        return result.data;
    }

    async GetDecuiusById(decuiusId: string): Promise<DecuiusInfo> {
        const result = await axios.post<DecuiusInfo>(`${serviceConfig.appServiceBaseUrl}/GetDecuius`, decuiusId);
        return result.data;
    }

    async DeleteDecuius(decuiusId: string): Promise<void> {
        const result = await axios.post(`${serviceConfig.appServiceBaseUrl}/DeleteDecuius`, decuiusId);
        return result.data;
    }

    async UpsertDecuius(decuius: Decuius): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/UpsertDecuius`, decuius);
        return result.data;
    }

    async GetDossiersByDecuiusId(paginationQuery: PaginationQuery, decuiusId: string): Promise<PaginationResult<Pratica>> {
        const result = await axios.post<PaginationResult<Pratica>>(`${serviceConfig.appServiceBaseUrl}/GetDossiersByDecuiusId`, {
            pagination: paginationQuery,
            decuiusId: decuiusId
        });
        return result.data;
    }

    async GetAllTransfers(paginationQuery: PaginationQuery, filters: object): Promise<PaginationResult<Transfer>> {
        const result = await axios.post<PaginationResult<Transfer>>(`${serviceConfig.appServiceBaseUrl}/GetAllTransfers`, {
            ...paginationQuery,
            ...filters
        });
        return result.data;
    }

    async AttachFileToDecuius(attachFileToDecuius: object): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/AttachFileToDecuius`, attachFileToDecuius);
        return result.data;
    }

    async GetDecuiusAttachments(decuiusId: string): Promise<Array<Allegato>> {
        const result = await axios.post<Array<Allegato>>(`${serviceConfig.appServiceBaseUrl}/GetDecuiusAttachments`, decuiusId);
        return result.data;
    }

    async RenameDecuiusAttachment(RenameDecuiusAttachment: object): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/RenameDecuiusAttachment`, RenameDecuiusAttachment);
        return result.data;
    }

    async DownloadDecuiusAttachment(attachmentId: string): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/DownloadDecuiusAttachment`, attachmentId);
        return result.data;
    }

    async DeleteDecuiusAttachment(attachmentId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/DeleteDecuiusAttachment`, attachmentId);
        return result.data;
    }
}