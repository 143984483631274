import { Box, Button, Card, Grid, TablePagination, TextField, debounce, useMediaQuery, useTheme } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PaginationQuery, defaultPaginationQuery } from "types/paginationQuery";
import { PaginationResult, voidPagination } from "types/paginationResult";
import { getFormValue } from "utils/common";
import PostiPageMobile from "./PostiPageMobile";
import PostiPage from "./PostiPage";
import { PostoService } from "services/PostoServices";
import { Posto } from "types/posto";

interface PostiListProps {
    assignPostoToEntity?: (postoId: string) => void;
    alreadyAssignedPostiIds: Array<string>;
    massivePlaceAssignment: () => void;

    checkedPlaces: Array<string>;
    checkAllPlaces: (e: any,posti: Array<Posto>) => void;
    checkPlace: (e: any, postoId: string) => void;
    placesSurnameFilterRef?: any;
}

interface PostiFilters {
    cognome: string;
    nome: string;
    numero: string;
    struttura: string;
}

const PostiList: FC<PostiListProps> = (props) => {
    const { assignPostoToEntity, alreadyAssignedPostiIds, massivePlaceAssignment, checkedPlaces, checkAllPlaces, checkPlace, placesSurnameFilterRef } = props;
    const intl = useIntl();

    const postoService = new PostoService();

    const [posti, setPosti] = useState<PaginationResult<Posto>>(voidPagination);

    const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>(({ ...defaultPaginationQuery, pageSize: 10 }));
    const [filters, setFilters] = useState<PostiFilters>({
        cognome: "",
        nome: "",
        numero: "",
        struttura: ""
    });

    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

    const getPosti = useCallback(debounce(async (paginationQuery: PaginationQuery, filters: PostiFilters) => {
        const retrievedPosti = await postoService.GetPosti(paginationQuery, filters);
        setPosti(retrievedPosti);

        setIsPageLoading(false);
    }, 700), []);

    const updatePosti = () => {
        setIsPageLoading(true);
        getPosti(paginationQuery, filters);
    }

    useEffect(() => {
        updatePosti();
    }, [paginationQuery, filters]);

    const handleFiltersChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setFilters((currentFormData: any) => ({ ...currentFormData, [name]: value }));
    }

    const setPageSize = (newPageSize: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageSize: newPageSize }))
    }

    const setPageNumber = (newPageNumber: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: newPageNumber }))
    }

    const setSortOptions = (sortColumnName: string) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, sortColumn: sortColumnName }));
        setPaginationQuery((currentData: any) => ({ ...currentData, ascending: !paginationQuery.ascending }));
    }

    const assignPosto = (postoId: string) => {
        if (assignPostoToEntity !== undefined) assignPostoToEntity(postoId);
    }

    var currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

    return (
        <>
            <Grid container spacing={1} sx={{ mb: 2 }}>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="surname" />}
                        name="cognome"
                        onChange={handleFiltersChange}
                        inputRef={placesSurnameFilterRef}
                    />
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="name" />}
                        name="nome"
                        onChange={handleFiltersChange}
                    />
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="number" />}
                        name="numero"
                        onChange={handleFiltersChange}
                    />
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="structure" />}
                        name="strutturaDenominazione"
                        onChange={handleFiltersChange}
                    />
                </Grid>
            </Grid>
            <Card>
                {
                    mobileDevice ?
                        <PostiPageMobile
                            posti={posti}
                            alreadyAssignedPostiIds={alreadyAssignedPostiIds}
                            assignPosto={assignPosto}

                            isPageLoading={isPageLoading}

                            checkAllPlaces={checkAllPlaces}
                            checkedPlaces={checkedPlaces}
                            checkPlace={checkPlace}
                        /> :
                        <PostiPage
                            posti={posti}
                            alreadyAssignedPostiIds={alreadyAssignedPostiIds}
                            assignPosto={assignPosto}

                            setSortOptions={setSortOptions}
                            sortOptions={{ sortColumn: paginationQuery.sortColumn, ascending: paginationQuery.ascending }}

                            isPageLoading={isPageLoading}

                            checkAllPlaces={checkAllPlaces}
                            checkedPlaces={checkedPlaces}
                            checkPlace={checkPlace}
                        />
                }
                <Button variant="contained" sx={{ mt: '10px' }} disabled={checkedPlaces.length < 1} onClick={massivePlaceAssignment}>
                    <FormattedMessage id="massiveAssignment" />
                </Button>
                <TablePagination
                    component="div"
                    count={posti?.totalCount}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10));
                    }}
                    onPageChange={(e, page) => {
                        setPageNumber(page);
                    }}
                    page={paginationQuery.pageNumber}
                    rowsPerPage={paginationQuery.pageSize}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    labelRowsPerPage={<FormattedMessage id={mobileDevice ? "rows" : "rowsPerPage"} />}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'of' })} ${count}`}
                />
            </Card>
        </>
    );

}

export default PostiList;