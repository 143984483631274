import { Grid, TextField, Card, CardHeader, Divider, TablePagination, useMediaQuery, useTheme, debounce, Dialog, DialogTitle, DialogActions, Button } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { FatturaService } from "services/FatturaServices";
import { Fattura } from "types/fattura";
import { PaginationQuery, defaultPaginationQuery } from "types/paginationQuery";
import { PaginationResult, voidPagination } from "types/paginationResult";
import { composeNotistackMessage, getFormValue, handleDownloadFile, isNotNullOrUndefined, retrieveCookieByKey, saveFiltersPaginationCookie } from "utils/common";
import FatturePage from "./FatturePage";
import FatturePageMobile from "./FatturePageMobile";
import DialogDeleteElement from "ui-component/DialogDeleteElement";
import BackdropLoader from "ui-component/BackdropLoader";
import { useSelector } from "store";

interface FattureFilters {
    serie: string;
    numero: string;
    anno: string;
    nominativo: string;
}

const FattureList = () => {
    const intl = useIntl();

    const { loggedUser } = useSelector((state) => state.loggedUser);

    const fatturaService = new FatturaService();

    const [fatture, setFatture] = useState<PaginationResult<Fattura>>(voidPagination);

    const [openSendEInvoiceDialog, setOpenSendEInvoiceDialog] = useState<boolean>(false);
    const [eInvoiceToSendData, setEInvoiceToSendData] = useState<{ id: string, invoiceNumber: string } | null>(null);

    const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>(defaultPaginationQuery);
    const [filters, setFilters] = useState<FattureFilters>({
        serie: "",
        numero: "",
        anno: "",
        nominativo: ""
    });

    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

    useEffect(() => {
        const filtersPaginationCookie = retrieveCookieByKey(loggedUser.currentTenantId, 'invoicesListFilters');
        if (isNotNullOrUndefined(filtersPaginationCookie)) {
            setFilters(filtersPaginationCookie.filters);
            setPaginationQuery(filtersPaginationCookie.paginationQuery);
        }
    }, [])

    const updateFatture = () => {
        setIsPageLoading(true);
        getFatture(paginationQuery, filters);

        saveFiltersPaginationCookie(loggedUser.currentTenantId, 'invoicesListFilters', {
            filters: filters,
            paginationQuery: paginationQuery
        }, 1);
    }

    const getFatture = useCallback(debounce(async (paginationQuery: PaginationQuery, filters: FattureFilters) => {
        const retrievedFatture = await fatturaService.GetFatture(paginationQuery, filters);
        setFatture(retrievedFatture);

        setIsPageLoading(false);
    }, 700), []);

    useEffect(() => {
        updateFatture();
    }, [paginationQuery, filters]);

    var currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

    const handleFiltersChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setFilters((currentFormData: any) => ({ ...currentFormData, [name]: value }));
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: 0 }));
    }

    const setPageSize = (newPageSize: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageSize: newPageSize }))
    }

    const setPageNumber = (newPageNumber: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: newPageNumber }))
    }

    const setSortOptions = (sortColumnName: string) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, sortColumn: sortColumnName }));
        setPaginationQuery((currentData: any) => ({ ...currentData, ascending: !paginationQuery.ascending }));
    }

    const deleteFattura = async (fatturaId: string) => {
        await fatturaService.DeleteFatturaById(fatturaId);
        updateFatture();
    };

    const downloadFattura = async (fatturaId: string, fatturaName: string) => {
        setIsLoadingInvoiceSendDownload(true);

        try {
            const fatturaBase64: string = await fatturaService.DownloadFatturaById(fatturaId);
            handleDownloadFile(fatturaBase64, fatturaName);
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        } finally {
            setIsLoadingInvoiceSendDownload(false);
        }
    }

    const handleEInvoiceToSend = async (invoiceId: string, invoiceNumber: string) => {
        setEInvoiceToSendData({
            id: invoiceId,
            invoiceNumber: invoiceNumber
        });

        setOpenSendEInvoiceDialog(true);
    }

    const sendEInvoice = async () => {
        setIsLoadingInvoiceSendDownload(true);

        try {
            await fatturaService.SendElectronicInvoice(eInvoiceToSendData!.id);
            composeNotistackMessage(intl.formatMessage({ id: 'invoiceSuccessfullySent' }), 'success');
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        } finally {
            setIsLoadingInvoiceSendDownload(false);
        }
    }

    const [entityToRemoveData, setEntityToRemoveData] = useState<{ id: string, name: string } | undefined>();

    const [removeDecuiusBoxDialogOpen, setRemoveDecuiusBoxDialogOpen] = useState<boolean>(false);

    const updateEntityToRemoveData = (dataToRemove: { id: string, name: string }) => {
        setEntityToRemoveData(dataToRemove);
        setRemoveDecuiusBoxDialogOpen(true);
    }

    const [isLoadingInvoiceSendDownload, setIsLoadingInvoiceSendDownload] = useState<boolean>(false);

    const emptyFilters = () => {
        setFilters({
            serie: "",
            numero: "",
            anno: "",
            nominativo: ""
        });
    }

    return (
        <>
            <Grid container spacing={1} sx={{ mb: 2 }}>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        autoComplete="off"
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="series" />}
                        name="serie"
                        onChange={handleFiltersChange}
                        value={filters.serie}
                    />
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        autoComplete="off"
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="number" />}
                        name="numero"
                        onChange={handleFiltersChange}
                        value={filters.numero}
                        autoFocus
                    />
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        autoComplete="off"
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="year" />}
                        name="anno"
                        onChange={handleFiltersChange}
                        value={filters.anno}
                    />
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        autoComplete="off"
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="name" />}
                        name="nominativo"
                        onChange={handleFiltersChange}
                        value={filters.nominativo}
                    />
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <Button variant="contained" onClick={emptyFilters}> <FormattedMessage id="emptyFilters" /> </Button>
                </Grid>
            </Grid>
            <Card>
                <BackdropLoader open={isLoadingInvoiceSendDownload} />

                <DialogDeleteElement
                    open={removeDecuiusBoxDialogOpen}
                    onClose={() => setRemoveDecuiusBoxDialogOpen(false)}
                    onCancel={() => setRemoveDecuiusBoxDialogOpen(false)}
                    onConfirm={() => {
                        setRemoveDecuiusBoxDialogOpen(false)
                        deleteFattura(entityToRemoveData!.id);
                    }}
                    entityToDeleteInfo={entityToRemoveData?.name ?? ''}
                    customMessageIntlId="confirmDeletion"
                    key="removeDecuiusBox"
                />
                <Dialog
                    open={openSendEInvoiceDialog}
                    onClose={() => setOpenSendEInvoiceDialog(false)}
                    aria-labelledby="alert-send-einvoice-title"
                    aria-describedby="alert-send-einvoice-description"
                >
                    <DialogTitle id="send-einvoice-title">
                        <FormattedMessage id="confirmSendEinvoice" />: <b>{eInvoiceToSendData?.invoiceNumber}</b>?
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => {
                            setOpenSendEInvoiceDialog(false);
                            setEInvoiceToSendData(null);
                        }} variant="outlined" color="info"> <FormattedMessage id="cancel" /> </Button>
                        <Button onClick={() => {
                            setOpenSendEInvoiceDialog(false);
                            sendEInvoice();
                            setEInvoiceToSendData(null);
                        }} variant="contained" color="info"><FormattedMessage id="send" /></Button>
                    </DialogActions>
                </Dialog>
                <CardHeader title={<FormattedMessage id="invoicesList" />} />
                <Divider />
                {
                    mobileDevice ?
                        <FatturePageMobile
                            fatture={fatture}
                            downloadFattura={downloadFattura}
                            handleEInvoiceToSend={handleEInvoiceToSend}

                            isPageLoading={isPageLoading}

                            updateEntityToRemoveData={updateEntityToRemoveData}
                        /> :
                        <FatturePage
                            fatture={fatture}
                            downloadFattura={downloadFattura}
                            handleEInvoiceToSend={handleEInvoiceToSend}

                            setSortOptions={setSortOptions}
                            sortOptions={{ sortColumn: paginationQuery.sortColumn, ascending: paginationQuery.ascending }}
                            isPageLoading={isPageLoading}

                            updateEntityToRemoveData={updateEntityToRemoveData}
                        />
                }
                <TablePagination
                    component="div"
                    count={fatture?.totalCount}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10));
                    }}
                    onPageChange={(e, page) => {
                        setPageNumber(page);
                    }}
                    page={paginationQuery.pageNumber}
                    rowsPerPage={paginationQuery.pageSize}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    labelRowsPerPage={<FormattedMessage id={mobileDevice ? "rows" : "rowsPerPage"} />}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'of' })} ${count}`}
                />
            </Card>
        </>
    );
}

export default FattureList;