/* eslint-disable import/prefer-default-export */
/* eslint-disable class-methods-use-this */
/* eslint-disable prettier/prettier */
import axios from 'axios';

import serviceConfig from '../ServiceConfig';
import { PaginationQuery } from 'types/paginationQuery';
import { PaginationResult } from 'types/paginationResult';
import { Pratica } from 'types/pratica';
import { Utente } from 'types/utente';
import { Allegato } from 'types/allegato';
import { PostoPratica } from 'types/postoPratica';
import { ServizioPosto } from 'types/servizioPosto';
import { Fattura } from 'types/fattura';
import GetDossierDocument from 'interfaces/GetDossierDocument';
import { PostoPraticaData } from 'types/postoPraticaData';
import { UserPaymentsReport } from 'types/userPaymentsReport';
import GetDossierDocumentFilledByTemplate from 'types/getDossierDocumentFilledByTemplate';
import { DossierReport } from 'types/dossierReport';
import { DossiersCompleteReport } from 'types/dossiersCompleteReport';
import { PlaceServiceToInvoice } from 'types/placeServiceToInvoice';
import { SerieNumeratore } from 'types/serieNumeratore';
import { UserPaymentToDo } from 'types/userPaymentToDo';
import { ServizioPostoWithPeriodData } from 'types/servizioPostoWithPeriodData';

export class PraticaService {
    async GetPratiche(paginationQuery: PaginationQuery, filters: object): Promise<PaginationResult<Pratica>> {
        const result = await axios.post<PaginationResult<Pratica>>(`${serviceConfig.appServiceBaseUrl}/GetPratiche`, {
            ...paginationQuery,
            ...filters
        });
        return result.data;
    }

    async InsertPratica(praticaData: any): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/InsertPratica`, praticaData);
        return result.data;
    }

    async UpdatePratica(praticaData: any): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/UpdatePratica`, praticaData);
        return result.data;
    }

    async GetPraticaById(praticaId: string): Promise<Pratica> {
        const result = await axios.post<Pratica>(`${serviceConfig.appServiceBaseUrl}/GetPratica`, praticaId);
        return result.data;
    }

    async AssignUserToPratica(assignUserToPraticaObject: object): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/AssignUserToPratica`, assignUserToPraticaObject);
        return result.data;
    }

    async GetPraticaHolder(praticaId: string): Promise<Utente> {
        const result = await axios.post<Utente>(`${serviceConfig.appServiceBaseUrl}/GetPraticaHolder`, praticaId);
        return result.data;
    }

    async InsertAllegatoToPratica(insertPostoAllegato: object): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/InsertPraticaAllegato`, insertPostoAllegato);
        return result.data;
    }

    async GetPraticaAllegati(praticaId: string): Promise<Array<Allegato>> {
        const result = await axios.post<Array<Allegato>>(`${serviceConfig.appServiceBaseUrl}/GetPraticaAllegati`, praticaId);
        return result.data;
    }

    async DeleteAllegatoFromPratica(allegatoId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/DeletePraticaAllegato`, allegatoId);
        return result.data;
    }

    async GetPraticaPosti(praticaId: string): Promise<Array<PostoPraticaData>> {
        const result = await axios.post<Array<PostoPraticaData>>(`${serviceConfig.appServiceBaseUrl}/GetPraticaPosti`, praticaId);
        return result.data;
    }

    async RemovePostoFromPratica(postoPraticaId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/RemovePostoFromPratica`, postoPraticaId);
        return result.data;
    }

    async GetPostoServizi(postoPraticaId: string): Promise<Array<ServizioPostoWithPeriodData>> {
        const result = await axios.post<Array<ServizioPostoWithPeriodData>>(`${serviceConfig.appServiceBaseUrl}/GetPostoServizi`, postoPraticaId);
        return result.data;
    }

    async AssignServizioToPosto(servizioPosto: object): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/AssignServizioToPosto`, servizioPosto);
        return result.data;
    }

    async RemoveServizioFromPosto(postoServizioId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/RemoveServizioFromPosto`, postoServizioId);
        return result.data;
    }

    async DeletePraticaById(praticaId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/DeletePratica`, praticaId);
        return result.data;
    }

    async GetPraticaFatture(praticaId: string): Promise<Array<Fattura>> {
        const result = await axios.post<Array<Fattura>>(`${serviceConfig.appServiceBaseUrl}/GetPraticaFatture`, praticaId);
        return result.data;
    }

    async GetServicesPlacesToInvoice(praticaId: string): Promise<Array<PlaceServiceToInvoice>> {
        const result = await axios.post<Array<PlaceServiceToInvoice>>(`${serviceConfig.appServiceBaseUrl}/GetPostiServizi`, praticaId);
        return result.data;
    }

    async GetDossierDocument(getDossierDocument: GetDossierDocument): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/GetDossierDocument`, getDossierDocument);
        return result.data;
    }

    async GetFilledDocumentByTemplate(getDossierDocumentFilledByTemplate: GetDossierDocumentFilledByTemplate): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/GetFilledDocumentByTemplate`, getDossierDocumentFilledByTemplate);
        return result.data;
    }

    async GetUserDossiers(paginationQuery: PaginationQuery, filters: object): Promise<PaginationResult<Pratica>> {
        const result = await axios.post<PaginationResult<Pratica>>(`${serviceConfig.appServiceBaseUrl}/GetUserDossiers`, {
            ...paginationQuery,
            ...filters
        });
        return result.data;
    }

    async GetPlaceServicesPaymentsReport(): Promise<UserPaymentsReport> {
        const result = await axios.get<UserPaymentsReport>(`${serviceConfig.appServiceBaseUrl}/GetPlaceServicesPaymentsReport`);
        return result.data;
    }

    async GetPlaceServicesToPayByDossierId(dossierId: string): Promise<Array<UserPaymentToDo>> {
        const result = await axios.post<Array<UserPaymentToDo>>(`${serviceConfig.appServiceBaseUrl}/GetPlaceServicesToPayByDossierId`, dossierId);
        return result.data;
    }

    async GetUserDossiersNumber(): Promise<number> {
        const result = await axios.get<number>(`${serviceConfig.appServiceBaseUrl}/GetUserDossiersNumber`);
        return result.data;
    }

    async GetPraticheReport(paginationQuery: PaginationQuery, filters: object): Promise<DossiersCompleteReport> {
        const result = await axios.post<DossiersCompleteReport>(`${serviceConfig.appServiceBaseUrl}/GetPraticheReport`, {
            ...paginationQuery,
            ...filters
        });
        return result.data;
    }

    async GetPraticheReportExcel(paginationQuery: PaginationQuery, filters: object): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/GetPraticheReportExcel`, {
            ...paginationQuery,
            ...filters
        });
        return result.data;
    }

    async AssignPlacesToPratica(praticaId: string, checkedPlacesId: Array<string>): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/AssignPlacesToPratica`, { praticaId: praticaId, postiId: checkedPlacesId });
        return result.data;
    }

    async GetUnpaginatedPraticaSerieNumeratori(): Promise<SerieNumeratore[]> {
        const result = await axios.get<SerieNumeratore[]>(`${serviceConfig.appServiceBaseUrl}/GetAllPraticaSerieNumeratori`);
        return result.data;
    }

    async DownloadPraticaAllegato(documentId: string): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/DownloadPraticaAllegato`, documentId);
        return result.data;
    }

    async RenameAllegatoPratica(renameAllegatoObject: object): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/RenameAllegatoPratica`, renameAllegatoObject);
        return result.data;
    }

    async GetDocumentToReviewByTemplate(getDossierDocumentFilledByTemplate: GetDossierDocumentFilledByTemplate): Promise<string> {
        const result = await axios.post<string>(`${serviceConfig.appServiceBaseUrl}/GetDocumentToReviewByTemplate`, getDossierDocumentFilledByTemplate);
        return result.data;
    }

    async ConvertPlaceServiceToPayment(body: object): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/ConvertPlaceServiceToPayment`, body);
        return result.data;
    }
}