import { Button, Card, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, InputLabel, Menu, MenuItem, MenuProps, Select, TablePagination, TextField, alpha, debounce, styled, useMediaQuery, useTheme } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { UserService } from "services/UserServices";
import { PaginationQuery, defaultPaginationQuery } from "types/paginationQuery";
import { PaginationResult, voidPagination } from "types/paginationResult";
import { Utente } from "types/utente";
import { composeNotistackMessage, getFormValue, handleDownloadFile, isNotNullOrUndefined, retrieveCookieByKey, saveFiltersPaginationCookie } from "utils/common";
import UsersPageMobile from "./UsersPageMobile";
import UsersPage from "./UsersPage";
import { Link as RouterLink } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { UserTypeToGetPayments } from "types/enums/UserTypeToGetPayments";
import BackdropLoader from "ui-component/BackdropLoader";
import { TenantDocument } from "types/tenantDocument";
import { TenantService } from "services/TenantServices";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { dispatch, useSelector } from "store";
import { setQrCodeCommunicationsFileId } from "store/slices/qrCodeCommunicationsFileIdSlice";

interface UsersListProps {
    fromExternal: boolean;
    fromWhere?: string;
    alreadyAssignedUsersIds?: Array<string>;
    assignUserToEntity?: (userId: string) => void;
    customAddMethod?: () => void;
    usersSurnameFilterRef?: any;
}

interface UsersFilters {
    cognome: string;
    nome: string;
    comune: string;
    codiceFiscale: string;
    userType: UserTypeToGetPayments;
}

const ButtonSelectionMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const UsersList: FC<UsersListProps> = (props) => {
    const { fromExternal, fromWhere, alreadyAssignedUsersIds, assignUserToEntity, customAddMethod, usersSurnameFilterRef } = props;

    const { qrCodeCommunicationsFileId } = useSelector((state) => state.qrCodeCommunicationsFileId);

    const { loggedUser } = useSelector((state) => state.loggedUser);

    const intl = useIntl();

    const userService = new UserService();
    const tenantService = new TenantService();

    const [users, setUsers] = useState<PaginationResult<Utente>>(voidPagination);

    const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>(defaultPaginationQuery);
    const [filters, setFilters] = useState<UsersFilters>({
        cognome: "",
        nome: "",
        comune: "",
        codiceFiscale: "",
        userType: UserTypeToGetPayments.all
    });

    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

    useEffect(() => {
        const filtersPaginationCookie = retrieveCookieByKey(loggedUser.currentTenantId, 'usersListFilters');
        if (isNotNullOrUndefined(filtersPaginationCookie)) {
            setFilters(filtersPaginationCookie.filters);
            setPaginationQuery(filtersPaginationCookie.paginationQuery);
        }
    }, [])

    const getUsers = useCallback(debounce(async (paginationQuery: PaginationQuery, filters: UsersFilters) => {
        const retrievedUsers = await userService.GetUsers(paginationQuery, filters);
        setUsers(retrievedUsers);

        setIsPageLoading(false);
    }, 700), []);

    const updateUsers = () => {
        setIsPageLoading(true);
        getUsers(paginationQuery, filters);

        saveFiltersPaginationCookie(loggedUser.currentTenantId, 'usersListFilters', {
            filters: filters,
            paginationQuery: paginationQuery
        }, 1);
    }

    useEffect(() => {
        updateUsers();
    }, [paginationQuery, filters]);

    const [assignUserButtonText, setAssignUserButtonText] = useState<string>('');

    useEffect(() => {
        if (fromExternal) {
            let buttonText: string = '';
            switch (fromWhere) {
                case 'structure':
                    buttonText = 'assignDealer';
                    break;
                default:
                    buttonText = 'assignUser';
                    break;
            }

            setAssignUserButtonText(buttonText);
        } else {
            (async () => {
                const retrievedCommunicationDocuments: Array<TenantDocument> = await tenantService.GetTenantCommunicationDocuments();
                setCommunicationDocuments(retrievedCommunicationDocuments);
                setChosenCommunicationDocumentId(retrievedCommunicationDocuments[0]?.id ?? '')
            })();
        }
    }, []);

    const handleFiltersChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setFilters((currentFormData: any) => ({ ...currentFormData, [name]: value }));
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: 0 }));
    }

    const setPageSize = (newPageSize: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageSize: newPageSize }))
    }

    const setPageNumber = (newPageNumber: number) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, pageNumber: newPageNumber }))
    }

    const setSortOptions = (sortColumnName: string) => {
        setPaginationQuery((currentData: any) => ({ ...currentData, sortColumn: sortColumnName }));
        setPaginationQuery((currentData: any) => ({ ...currentData, ascending: !paginationQuery.ascending }));
    }

    const assignUser = (userId: string) => {
        if (assignUserToEntity !== undefined) assignUserToEntity(userId);
    }

    const [communicationDocuments, setCommunicationDocuments] = useState<Array<TenantDocument>>([]);

    const [isQrCodeLoading, setIsQrCodeLoading] = useState<boolean>(false);

    const [generateQrCodeCommunicationsDialogOpen, setGenerateQrCodeCommunicationsDialogOpen] = useState<boolean>(false);

    const SelectCommunicationsTemplate = () => {
        return (
            <FormControl fullWidth size='small' required sx={{ mt: 2 }}>
                <InputLabel> <FormattedMessage id="templateCommunication" /> </InputLabel>
                <Select
                    name="communicationTemplate"
                    label={<FormattedMessage id="templateCommunication" />}
                    sx={{ color: 'white' }}
                    defaultValue={communicationDocuments[0]?.id ?? undefined}
                    onChange={(e: any) => setChosenCommunicationDocumentId(e.target.value)}
                    value={chosenCommunicationDocumentId}
                >
                    {
                        communicationDocuments.map((document, index) => (
                            <MenuItem key={index} value={document.id}>
                                {document.fileName}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        )
    }

    const [dataToGenerateQrCodeCommunication, setDataToGenerateQrCodeCommunication] = useState<{ userId: string | null, justDossierHolders: boolean; fileId: string | null; }>({ userId: null, justDossierHolders: false, fileId: null });
    const [chosenCommunicationDocumentId, setChosenCommunicationDocumentId] = useState<string>('');

    const generateQrCodeCommunications = async () => {
        setIsQrCodeLoading(true);

        try {
            const qrCodeCommunicationObject: object = {
                ...dataToGenerateQrCodeCommunication,
                templateId: chosenCommunicationDocumentId
            };

            setGenerateQrCodeCommunicationsDialogOpen(false);

            await userService.GenerateCommunicationWithQrCode(qrCodeCommunicationObject);

            composeNotistackMessage(intl.formatMessage({ id: 'qrCodeCommunicationsGenerationStartedSuccessfully' }), 'success');
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
            dispatch(setQrCodeCommunicationsFileId(''));
        } finally {
            setIsQrCodeLoading(false);
        }
    }

    const generateAndSaveFileUUID = (): string => {
        const uuid: string = crypto.randomUUID();

        dispatch(setQrCodeCommunicationsFileId(uuid));

        return uuid;
    }

    const setupQrCodeCommunicationGeneration = (userId: string) => {
        setDataToGenerateQrCodeCommunication({ userId: userId, justDossierHolders: false, fileId: generateAndSaveFileUUID() });
        setGenerateQrCodeCommunicationsDialogOpen(true);
    }

    const setupQrCodeCommunicationGenerationForMoreUsers = (justDossierHolders: boolean) => {
        setDataToGenerateQrCodeCommunication({ userId: null, justDossierHolders: justDossierHolders, fileId: generateAndSaveFileUUID() });
        setGenerateQrCodeCommunicationsDialogOpen(true);
    }

    const [qrCodeCommunicationAnchorEl, setQrCodeCommunicationAnchorEl] = useState<null | HTMLElement>(null);
    const qrCodeCommunicationMenuOpen = Boolean(qrCodeCommunicationAnchorEl);

    const handleQrCodeCommunicationMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setQrCodeCommunicationAnchorEl(event.currentTarget);
    }

    const handleQrCodeCommunicationMenuClose = () => {
        setQrCodeCommunicationAnchorEl(null);
    }

    const handleGenerateQrCodeCommunicationsDialogClose = () => {
        setGenerateQrCodeCommunicationsDialogOpen(false);
        dispatch(setQrCodeCommunicationsFileId(''));
    }

    var currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

    const emptyFilters = () => {
        setFilters({
            cognome: "",
            nome: "",
            comune: "",
            codiceFiscale: "",
            userType: UserTypeToGetPayments.all
        });
    }

    return (
        <>
            <BackdropLoader open={isQrCodeLoading} />

            <Dialog
                open={generateQrCodeCommunicationsDialogOpen}
                onClose={handleGenerateQrCodeCommunicationsDialogClose}
                aria-labelledby="alert-send-communication"
                aria-describedby="alert-send-communication"
            >
                <DialogTitle id="send-communication-title">
                    <FormattedMessage id="confirmGenerationQrCodeCommunication" />?
                </DialogTitle>
                <DialogContent>
                    <SelectCommunicationsTemplate />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleGenerateQrCodeCommunicationsDialogClose} variant="outlined" color="error"> <FormattedMessage id="cancel" /> </Button>
                    <Button onClick={generateQrCodeCommunications} variant="contained" color="success"><FormattedMessage id="generate" /></Button>
                </DialogActions>
            </Dialog>

            <Grid container spacing={1} sx={{ mb: 2 }}>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        autoComplete="off"
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="surname" />}
                        name="cognome"
                        onChange={handleFiltersChange}
                        value={filters.cognome}
                        autoFocus
                        inputRef={usersSurnameFilterRef}
                    />
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        autoComplete="off"
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="name" />}
                        name="nome"
                        onChange={handleFiltersChange}
                        value={filters.nome}
                    />
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        autoComplete="off"
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="municipality" />}
                        name="comune"
                        onChange={handleFiltersChange}
                        value={filters.comune}
                    />
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={3}
                    xs={12}
                >
                    <TextField
                        autoComplete="off"
                        fullWidth
                        size='small'
                        label={<FormattedMessage id="fiscalCode" />}
                        name="codiceFiscale"
                        onChange={handleFiltersChange}
                        value={filters.codiceFiscale}
                    />
                </Grid>

                {!fromExternal &&
                    <>
                        <Grid
                            item
                            lg={3}
                            md={3}
                            xs={12}
                        >
                            <FormControl fullWidth size='small'>
                                <InputLabel> <FormattedMessage id="usersType" /> </InputLabel>
                                <Select
                                    name="userType"
                                    label={<FormattedMessage id="usersType" />}
                                    onChange={handleFiltersChange}
                                    defaultValue={filters.userType}
                                    value={filters.userType}
                                    sx={{ color: 'white' }}
                                >
                                    {
                                        Object.values(UserTypeToGetPayments).filter(key => isNaN(Number(key)))
                                            .map((userTypeToGetPayments, index) => (
                                                <MenuItem key={index} value={index}>
                                                    <FormattedMessage id={UserTypeToGetPayments[index]} />
                                                </MenuItem>
                                            ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            md={3}
                            xs={12}
                        >
                            <Button variant="contained" onClick={emptyFilters}> <FormattedMessage id="emptyFilters" /> </Button>
                        </Grid>
                        <Grid container direction="row">
                            <Grid
                                item
                                lg={8}
                                md={8}
                                xs={12}
                                sx={{ display: 'flex', justifyContent: mobileDevice ? 'center' : 'flex-start' }}
                            >
                                <Button
                                    id="generate-qr-code-communication"
                                    aria-controls={qrCodeCommunicationMenuOpen ? 'qr-code-communication-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={qrCodeCommunicationMenuOpen ? 'true' : undefined}
                                    variant="contained"
                                    disableElevation
                                    color="info"
                                    endIcon={isNotNullOrUndefined(qrCodeCommunicationsFileId) ? <CircularProgress size="1rem" /> : <KeyboardArrowDownIcon />}
                                    onClick={handleQrCodeCommunicationMenuOpen}
                                    startIcon={<QrCode2Icon />}
                                    sx={{ m: 2, textAlign: 'center' }}
                                    disabled={isNotNullOrUndefined(qrCodeCommunicationsFileId)}
                                >
                                    <FormattedMessage id="generateCommunicationWithQrCode" />
                                </Button>
                                <ButtonSelectionMenu
                                    id="qr-code-communication-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'generate-qr-code-communication',
                                    }}
                                    anchorEl={qrCodeCommunicationAnchorEl}
                                    open={qrCodeCommunicationMenuOpen}
                                    onClose={handleQrCodeCommunicationMenuClose}
                                >
                                    <MenuItem
                                        onClick={async () => {
                                            handleQrCodeCommunicationMenuClose();
                                            setupQrCodeCommunicationGenerationForMoreUsers(false);
                                        }}
                                        disableRipple
                                    >
                                        <FormattedMessage id="forAllUser" />
                                    </MenuItem>
                                    <MenuItem
                                        onClick={async () => {
                                            handleQrCodeCommunicationMenuClose();
                                            setupQrCodeCommunicationGenerationForMoreUsers(true);
                                        }}
                                        disableRipple
                                    >
                                        <FormattedMessage id="justForDossierHolder" />
                                    </MenuItem>
                                </ButtonSelectionMenu>
                            </Grid>
                            <Grid
                                item
                                lg={4}
                                md={4}
                                xs={12}
                                sx={{ display: 'flex', justifyContent: mobileDevice ? 'center' : 'flex-end' }}
                            >
                                <Button
                                    component={RouterLink}
                                    to="create"
                                    color="primary"
                                    sx={{ m: 2, textAlign: 'center' }}
                                    variant="contained"
                                    startIcon={<AddIcon />}
                                >
                                    <FormattedMessage id="addUser" />
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                }
                {
                    fromExternal && ['structure', 'dossier', 'place'].includes(fromWhere!) &&
                    <Grid container direction="row">
                        <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                            sx={{ display: 'flex', justifyContent: 'flex-start' }}
                        >
                            <Button
                                color="success"
                                sx={{ m: 2, textAlign: 'center' }}
                                variant="contained"
                                startIcon={<AddIcon />}
                                onClick={customAddMethod}
                            >
                                <FormattedMessage id="createAndAssignUser" />
                            </Button>
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Card>
                {!fromExternal && <CardHeader title={<FormattedMessage id="usersList" />} />}
                <Divider />
                {
                    mobileDevice ?
                        <UsersPageMobile
                            users={users}

                            fromExternal={fromExternal}
                            alreadyAssignedUsersIds={alreadyAssignedUsersIds}
                            assignUser={assignUser}
                            assignUserButtonText={assignUserButtonText}

                            isPageLoading={isPageLoading}

                            setupQrCodeCommunicationGeneration={setupQrCodeCommunicationGeneration}
                        /> :
                        <UsersPage
                            users={users}

                            fromExternal={fromExternal}
                            alreadyAssignedUsersIds={alreadyAssignedUsersIds}
                            assignUser={assignUser}
                            assignUserButtonText={assignUserButtonText}

                            setSortOptions={setSortOptions}
                            sortOptions={{ sortColumn: paginationQuery.sortColumn, ascending: paginationQuery.ascending }}

                            isPageLoading={isPageLoading}

                            setupQrCodeCommunicationGeneration={setupQrCodeCommunicationGeneration}
                        />
                }
                <TablePagination
                    component="div"
                    count={users?.totalCount}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10));
                    }}
                    onPageChange={(e, page) => {
                        setPageNumber(page);
                    }}
                    page={paginationQuery.pageNumber}
                    rowsPerPage={paginationQuery.pageSize}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    labelRowsPerPage={<FormattedMessage id={mobileDevice ? "rows" : "rowsPerPage"} />}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'of' })} ${count}`}
                />
            </Card>
        </>
    );

}

export default UsersList;