/* eslint-disable import/prefer-default-export */
/* eslint-disable class-methods-use-this */
/* eslint-disable prettier/prettier */
import axios from 'axios';
import serviceConfig from '../ServiceConfig';
import { ServizioPosto } from 'types/servizioPosto';
import { PlaceServiceValidPeriod } from 'types/placeServiceValidPeriod';

export class ServizioPostoService {
    async GetServizioPostoById(servizioPostoId: string): Promise<ServizioPosto> {
        const result = await axios.post<ServizioPosto>(`${serviceConfig.appServiceBaseUrl}/GetServizioPosto`, servizioPostoId);
        return result.data;
    }

    async UpdateServizioPosto(servizioPosto: ServizioPosto): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/UpdateServizioPosto`, servizioPosto);
        return result.data;
    }

    async GetServiziPostoByIds(serviziPostoIds: string[]): Promise<Array<ServizioPosto>> {
        const result = await axios.post<Array<ServizioPosto>>(`${serviceConfig.appServiceBaseUrl}/GetServiziPosto`, serviziPostoIds);
        return result.data;
    }

    async SuspendPlaceService(body: object): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/SuspendPlaceService`, body);
        return result.data;
    }

    async ReactivatePlaceService(serviceId: string, postoPraticaId: string): Promise<void> {
        const result = await axios.post<void>(`${serviceConfig.appServiceBaseUrl}/ReactivatePlaceService`, { serviceId, postoPraticaId });
        return result.data;
    }

    async GetMinDateAnnualPlaceService(serviceId: string, postoPraticaId: string): Promise<Date | null> {
        const result = await axios.post<Date | null>(`${serviceConfig.appServiceBaseUrl}/GetMinDateAnnualPlaceService`, { serviceId, postoPraticaId });
        return result.data;
    }

    async GetPlaceServiceValidPeriodsById(serviceId: string, postoPraticaId: string): Promise<PlaceServiceValidPeriod[]> {
        const result = await axios.post<PlaceServiceValidPeriod[]>(`${serviceConfig.appServiceBaseUrl}/GetPlaceServiceValidPeriodsById`, { serviceId, postoPraticaId });
        return result.data;
    }

    async GetPlaceServiceUnpayedPeriods(serviceId: string, postoPraticaId: string): Promise<number[]> {
        const result = await axios.post<number[]>(`${serviceConfig.appServiceBaseUrl}/GetPlaceServiceUnpayedPeriodById`, { serviceId, postoPraticaId });
        return result.data;
    }
}