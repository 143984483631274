import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, CardHeader, Paper, TableContainer, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Allegato } from "types/allegato";
import { PraticaService } from "services/PraticaServices";
import { composeNotistackMessage, getWithTwoDecimal, handleDownloadFile, isNotNullOrUndefined } from "utils/common";
import { Fattura } from "types/fattura";
import { FatturaService } from "services/FatturaServices";
import { Pratica } from "types/pratica";
import CardContentLoader from "ui-component/CardContentLoader";
import { PostoPraticaData } from "types/postoPraticaData";
import { PagamentoService } from "services/PagamentoServices";
import { PaymentRedirectData } from "types/paymentRedirectData";
import BackdropLoader from "ui-component/BackdropLoader";
import PaymentRedirectPage from "./PaymentRedirectPage";
import { useLocation } from "react-router-dom";
import { UserPaymentToDo } from "types/userPaymentToDo";
import { PaymentStatus } from "types/enums/PaymentStatus";
import PaymentsToDoList from "./PaymentsToDoList";
import PaymentsToDoListMobile from "./PaymentsToDoListMobile";
import PlacesList from "./PlacesList";
import PlacesListMobile from "./PlacesListMobile";
import AttachmentsList from "./AttachmentsList";
import AttachmentsListMobile from "./AttachmentsListMobile";
import InvoicesList from "./InvoicesList";
import InvoicesListMobile from "./InvoicesListMobile";

const DossierInfo = () => {
    const intl = useIntl();

    const location = useLocation();

    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
    const [isPaymentRedirectLoading, setIsPaymentRedirectLoading] = useState<boolean>(false);

    const [dossierNumber, setDossierNumber] = useState<string>('');
    const [files, setFiles] = useState<Array<Allegato>>([]);
    const [fatture, setFatture] = useState<Array<Fattura>>([]);
    const [dossierPlaces, setDossierPlaces] = useState<Array<PostoPraticaData>>([]);
    const [servicesToPay, setServicesToPay] = useState<Array<UserPaymentToDo>>([]);

    const praticaService = new PraticaService();
    const fatturaService = new FatturaService();

    const pagamentoService = new PagamentoService();

    const [dossierId, setDossierId] = useState<string>('');

    const [paymentRedirectData, setPaymentRedirectData] = useState<PaymentRedirectData | null>(null);

    var currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

    const checkPaymentResult = async () => {
        const url: string = window.location.href;

        let placeServiceId: string = url.split('placeServiceId=')[1] ?? '';
        if (placeServiceId !== '') {
            placeServiceId = placeServiceId.split('&')[0];
        }

        let nome: string = url.split('&nome=')[1] ?? '';
        if (nome !== '') {
            nome = nome.split('&')[0];
        }

        let cognome: string = url.split('&cognome=')[1] ?? '';
        if (cognome !== '') {
            cognome = cognome.split('&')[0];
        }

        let esito: string = url.split('&esito=')[1] ?? '';
        if (esito !== '') {
            esito = esito.split('&')[0];
        }

        let paymentCode: string = url.split('&codTrans=')[1] ?? '';
        if (paymentCode !== '') {
            paymentCode = paymentCode.split('&')[0];
            paymentCode = paymentCode.slice(0, -4);
        }

        const paymentCodeFromLocalStorage: string = localStorage.getItem('PaymentCode') ?? '';

        if (isNotNullOrUndefined(placeServiceId) && isNotNullOrUndefined(esito) && isNotNullOrUndefined(paymentCode)) {
            if (isNotNullOrUndefined(nome) && isNotNullOrUndefined(cognome)) {
                if (esito == 'OK') {
                    await updatePayment(paymentCode, `${nome} ${cognome}`, PaymentStatus.Completed);
                } else {
                    await updatePayment(paymentCode, `${nome} ${cognome}`, PaymentStatus.Rejected);
                }
            } else {
                await updatePayment(paymentCode, '', PaymentStatus.MissingUserData);
            }
        } else if (isNotNullOrUndefined(paymentCodeFromLocalStorage)) {
            await updatePayment(paymentCodeFromLocalStorage, '', PaymentStatus.MissingPaymentData);
        }

        localStorage.removeItem('PaymentCode');
    }

    const updatePayment = async (paymentCode: string, payerFullName: string, paymentStatus: PaymentStatus) => {
        try {
            const paymentData = {
                paymentCode: paymentCode,
                payerFullName: payerFullName,
                paymentStatus: paymentStatus
            };

            await pagamentoService.UpdateUserPayment(paymentData);

            switch (paymentStatus) {
                case PaymentStatus.Rejected:
                    composeNotistackMessage(intl.formatMessage({ id: 'paymentfailed' }), 'error');
                    break;
                case PaymentStatus.Completed:
                    composeNotistackMessage(intl.formatMessage({ id: 'paymentCompleted' }), 'success');
                    break;
                default:
                    composeNotistackMessage(intl.formatMessage({ id: 'paymentCompletedButWarning' }), 'warning');
                    break;
            }
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        }
    }

    const checkPaymentToDo = async () => {
        const url: string = window.location.href;

        let placeServiceId: string = url.split('placeServiceId=')[1] ?? '';
        if (placeServiceId !== '') {
            placeServiceId = placeServiceId.split('&')[0];
        }

        let nome: string = url.split('&nome=')[1] ?? '';
        if (nome !== '') {
            nome = nome.split('&')[0];
        }

        let cognome: string = url.split('&cognome=')[1] ?? '';
        if (cognome !== '') {
            cognome = cognome.split('&')[0];
        }

        let esito: string = url.split('&esito=')[1] ?? '';
        if (esito !== '') {
            esito = esito.split('&')[0];
        }

        if (isNotNullOrUndefined(placeServiceId) && !isNotNullOrUndefined(nome) && !isNotNullOrUndefined(cognome) && !isNotNullOrUndefined(esito)) {
            const userPaymentToDo: UserPaymentToDo | undefined = servicesToPay.find((userPaymentToDo: UserPaymentToDo) => userPaymentToDo.placeServiceId == placeServiceId);
            if (isNotNullOrUndefined(userPaymentToDo)) {
                await executePayment(getWithTwoDecimal(userPaymentToDo!.importo - userPaymentToDo!.pagato!), userPaymentToDo!.placeServiceId);
            }
        }
    }

    useEffect(() => {
        setIsPageLoading(true);

        (async () => {
            await checkPaymentResult();

            let praticaId: string = window.location.href.split('dossierId=')[1] ?? '';
            if (praticaId !== '') {
                praticaId = praticaId.split('&')[0];
                setDossierId(praticaId);
            }
            const retrievedPratica: Pratica = await praticaService.GetPraticaById(praticaId);
            setDossierNumber(retrievedPratica.numero!);

            const retrievedPostiPratica: Array<PostoPraticaData> = await praticaService.GetPraticaPosti(praticaId);
            setDossierPlaces(retrievedPostiPratica);

            const retrievedAllegati: Array<Allegato> = await praticaService.GetPraticaAllegati(praticaId);
            setFiles(retrievedAllegati);

            const retrievedFatture: Array<Fattura> = await praticaService.GetPraticaFatture(praticaId);
            setFatture(retrievedFatture);

            const retrievedPlaceServicesToPay: Array<UserPaymentToDo> = await praticaService.GetPlaceServicesToPayByDossierId(praticaId);
            setServicesToPay(retrievedPlaceServicesToPay);

            setIsPageLoading(false);
        })();
    }, [])

    useEffect(() => {
        (async () => {
            if (servicesToPay.length > 0) {
                await checkPaymentToDo();
            }
        })()
    }, [servicesToPay])

    const downloadFattura = async (fatturaId: string, fatturaName: string) => {
        const fatturaBase64: string = await fatturaService.DownloadFatturaById(fatturaId);
        handleDownloadFile(fatturaBase64, fatturaName);
    }

    const executePayment = async (amountToPay: string, placeServiceId: string) => {
        setIsPaymentRedirectLoading(true);

        try {
            const url: string = `${location.pathname}?dossierId=${dossierId}`;

            const retrievedPaymentRedirectData: PaymentRedirectData | null = await pagamentoService.ExecutePayment(amountToPay, `${url}&placeServiceId=${placeServiceId}`);

            localStorage.setItem("PaymentCode", retrievedPaymentRedirectData?.paymentCode ?? '');

            setPaymentRedirectData(retrievedPaymentRedirectData);
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        } finally {
            setIsPaymentRedirectLoading(false);
        }
    }

    const resetPaymentRedirectData = () => {
        setPaymentRedirectData(null);
    }

    const downloadAllegato = async (file: any) => {
        const allegatoBase64: string = await praticaService.DownloadPraticaAllegato(file.id!);
        handleDownloadFile(allegatoBase64, file.nomeFile);
    }

    return (
        <Card>
            <BackdropLoader open={isPaymentRedirectLoading} />

            <CardHeader title={<> <FormattedMessage id="dossier" />: {dossierNumber} </>}></CardHeader>
            {
                isPageLoading ?
                    <CardContentLoader /> :
                    <CardContent sx={{ pt: 0 }}>
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="dossierPaymentsToDoSummary"
                                id="dossierPaymentsToDoSummary"
                            >
                                <h3> <FormattedMessage id="paymentsToDo" /> </h3>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer component={Paper}>
                                    {
                                        mobileDevice ?
                                            <PaymentsToDoListMobile dossierPlaces={dossierPlaces} executePayment={executePayment} servicesToPay={servicesToPay} /> :
                                            <PaymentsToDoList dossierPlaces={dossierPlaces} executePayment={executePayment} servicesToPay={servicesToPay} />
                                    }
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="dossierPlacesSummary"
                                id="dossierPlacesSummary"
                            >
                                <h3> <FormattedMessage id="places" /> </h3>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer component={Paper}>
                                    {
                                        mobileDevice ?
                                            <PlacesListMobile dossierPlaces={dossierPlaces} /> :
                                            <PlacesList dossierPlaces={dossierPlaces} />
                                    }
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="dossierFilesSummary"
                                id="dossierFilesSummary"
                            >
                                <h3> <FormattedMessage id="attachments" /> </h3>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer component={Paper}>
                                    {
                                        mobileDevice ?
                                            <AttachmentsListMobile files={files} downloadAllegato={downloadAllegato} /> :
                                            <AttachmentsList files={files} downloadAllegato={downloadAllegato} />
                                    }
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="dossierInvoicesSummary"
                                id="dossierInvoicesSummary"
                            >
                                <h3> <FormattedMessage id="invoices" /> </h3>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer component={Paper}>
                                    {
                                        mobileDevice ?
                                            <InvoicesListMobile fatture={fatture} downloadFattura={downloadFattura} /> :
                                            <InvoicesList fatture={fatture} downloadFattura={downloadFattura} />
                                    }
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>

                        {
                            isNotNullOrUndefined(paymentRedirectData) &&
                            <PaymentRedirectPage paymentRedirectData={paymentRedirectData!} resetPaymentRedirectData={resetPaymentRedirectData} />
                        }
                    </CardContent>
            }
        </Card>
    );
}

export default DossierInfo;