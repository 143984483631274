import { Card, CardHeader, Divider, Button, CardContent, Grid, TextField, Dialog, DialogTitle, IconButton, DialogActions, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ServizioPostoService } from "services/ServizioPostoServices";
import { PlaceServiceValidPeriod } from "types/placeServiceValidPeriod";
import { ServizioPosto } from "types/servizioPosto";
import CardContentLoader from "ui-component/CardContentLoader";
import { composeNotistackMessage, getCurrencyByNumber, getFormValue, getShortDateWithoutDays } from "utils/common";
import { handleUpsertErrors } from "utils/validation";
import { date, number, object } from "yup";
import CloseIcon from '@mui/icons-material/Close';

const ServizioPostoUpdate = () => {
    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
    const [servizioPosto, setServizioPosto] = useState<ServizioPosto | undefined>(undefined);

    const [validPeriods, setValidPeriods] = useState<PlaceServiceValidPeriod[]>([]);
    const [notPayedYears, setNotPayedYears] = useState<number[]>([]);

    const [suspendDialogOpen, setSuspendDialogOpen] = useState<boolean>(false);

    const [yearToSuspend, setYearToSuspend] = useState<number | null>(null);

    const [totalAmount, setTotalAmount] = useState<number>(0);

    const servizioPostoService = new ServizioPostoService();

    const intl = useIntl();

    const [praticaId, setPraticaId] = useState<string>('');

    const navigate = useNavigate();

    const [minDate, setMinDate] = useState<Date | null>(null);

    let servizioPostoValidationSchema = object({
        data: date().typeError('dateMustBeDate'),
        prezzo: number().required('priceRequired').min(0, 'priceMinZero').typeError('priceMustBeNumber'),
        aliquota: number().required().min(0, 'rateMinZero').typeError('rateMustBeNumber'),
        quantita: number().min(1, 'quantityMinOne').typeError('quantityMustBeNumber'),
    });

    useEffect(() => {
        setIsPageLoading(true);
        (async () => {
            let servizioPostoId: string = window.location.href.split('placeServiceId=')[1] ?? '';
            if (servizioPostoId !== '') {
                servizioPostoId = servizioPostoId.split('&')[0];
            }

            const retrievedServizioPosto: ServizioPosto = await servizioPostoService.GetServizioPostoById(servizioPostoId);
            setServizioPosto(retrievedServizioPosto);

            const retrievedValidPeriods: PlaceServiceValidPeriod[] = await servizioPostoService.GetPlaceServiceValidPeriodsById(retrievedServizioPosto.servizioAssociatoId, retrievedServizioPosto.postoPraticaAssociatoId);
            setValidPeriods(retrievedValidPeriods);

            const notPayedYears: number[] = await servizioPostoService.GetPlaceServiceUnpayedPeriods(retrievedServizioPosto.servizioAssociatoId, retrievedServizioPosto.postoPraticaAssociatoId);
            setNotPayedYears(notPayedYears);

            const retrievedMinDate = await servizioPostoService.GetMinDateAnnualPlaceService(retrievedServizioPosto.servizioAssociatoId, retrievedServizioPosto.postoPraticaAssociatoId);

            setMinDate(retrievedMinDate);

            let praticaIdQueryParam: string = window.location.href.split('dossierId=')[1] ?? '';
            setPraticaId(praticaIdQueryParam);

            setIsPageLoading(false);
        })();
    }, [])

    useEffect(() => {
        if (servizioPosto !== undefined) {
            const amount: number = servizioPosto!.quantita * (servizioPosto!.prezzo * (1 + (servizioPosto!.aliquota / 100)));
            setTotalAmount(Number.isNaN(amount) ? 0 : amount);
        }
    }, [servizioPosto?.quantita, servizioPosto?.prezzo, servizioPosto?.aliquota])

    const handleChange = (e: any) => {
        const { name, value } = getFormValue(e);
        setServizioPosto((currentFormData: any) => ({ ...currentFormData, [name]: value }));
    }

    const handleDateChange = (fieldName: string, date: any) => {
        let newValue: string | null;

        newValue = dayjs(date).format('YYYY-MM-DD');

        newValue = newValue == 'Invalid Date' ? null : newValue;

        setServizioPosto((currentFormData: any) => ({ ...currentFormData, [fieldName]: newValue }));
    }

    const servizioPostoUpdate = async () => {
        try {
            await servizioPostoValidationSchema.validate(servizioPosto, { abortEarly: false });
            await servizioPostoService.UpdateServizioPosto(servizioPosto!);

            navigate(`/dossiers/edit?dossierId=${praticaId}`);
        } catch (validationErrors: any) {
            handleUpsertErrors(validationErrors.inner, intl);
        }
    }

    const suspendPlaceService = async () => {
        try {
            await servizioPostoService.SuspendPlaceService({
                serviceId: servizioPosto!.servizioAssociatoId,
                postoPraticaId: servizioPosto!.postoPraticaAssociatoId,
                yearToSuspend
            });

            composeNotistackMessage(intl.formatMessage({ id: 'serviceSuspendedSuccessfully' }), 'success');

            navigate(`/dossiers/edit?dossierId=${praticaId}`);
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        }
    }

    const closeSuspendDialog = () => {
        setSuspendDialogOpen(false);
        setYearToSuspend(null);
    }

    const updateYearToSuspend = (event: React.ChangeEvent<HTMLInputElement>) => {
        setYearToSuspend(parseInt((event.target as HTMLInputElement).value));
    };

    return (
        <Card>
            <CardHeader title={<FormattedMessage id="serviceDetail" />} />
            <Divider />
            <Dialog
                open={suspendDialogOpen}
                onClose={closeSuspendDialog}
                aria-labelledby="suspend-card-title"
                aria-describedby="suspend-card-description"
                fullWidth
                maxWidth="md"
            >
                <DialogTitle id="suspend-place-service">
                    <FormattedMessage id="suspendPlaceService" />
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={closeSuspendDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Box sx={{ mx: 3 }}>
                    <Typography variant="h3" sx={{ mb: 1 }}>
                        <FormattedMessage id="servicePeriods" />
                    </Typography>
                    {
                        validPeriods
                            .map((validPeriod: PlaceServiceValidPeriod) => (
                                <Typography fontSize={16} variant="body2">
                                    <b><FormattedMessage id="from" /></b> {getShortDateWithoutDays(validPeriod.startPeriod)} <b><FormattedMessage id="to" /></b> {validPeriod.active ? <FormattedMessage id="nowActive" /> : getShortDateWithoutDays(validPeriod.endPeriod)}
                                </Typography>
                            ))
                    }
                    <Box sx={{ mt: 3 }}>
                        {
                            notPayedYears.length < 1 ?
                                <Typography variant="h4" color="error">
                                    <FormattedMessage id="serviceSuspensionNotPossible" />
                                </Typography> :
                                <FormControl>
                                    <FormLabel id="when-to-suspend">
                                        <Typography variant="h4">
                                            <FormattedMessage id="whenSuspendPeriod" />?
                                        </Typography>
                                    </FormLabel>
                                    <RadioGroup
                                        aria-labelledby="suspend-service-radio-buttons"
                                        name="suspend-service-radio-group"
                                        value={yearToSuspend}
                                        onChange={updateYearToSuspend}
                                    >
                                        {
                                            notPayedYears.map((year: number) => (
                                                <FormControlLabel value={year} control={<Radio />} label={<><FormattedMessage id="suspendfromYear" /> {year} </>} />
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                        }
                    </Box>
                </Box>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={closeSuspendDialog}><FormattedMessage id="cancel" /></Button>
                    <Button variant="contained" disabled={yearToSuspend === null} onClick={suspendPlaceService}><FormattedMessage id="confirm" /></Button>
                </DialogActions>
            </Dialog>
            {
                isPageLoading ?
                    <CardContentLoader /> :
                    (<CardContent>
                        <Grid container direction="row" spacing={1}>
                            <Grid
                                item
                                lg={4}
                                md={4}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    size='small'
                                    label={<FormattedMessage id="description" />}
                                    name="descrizione"
                                    onChange={handleChange}
                                    value={servizioPosto?.descrizione}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={4}
                                md={4}
                                xs={12}
                            >
                                <DatePicker
                                    views={['year', 'month']}
                                    inputFormat="MM/YYYY"
                                    label={<FormattedMessage id="date" />}
                                    minDate={minDate}
                                    onChange={(date: any) => {
                                        handleDateChange('data', date);
                                    }}
                                    renderInput={(params) => (
                                        <TextField fullWidth {...params} error={false} size='small' />
                                    )}
                                    value={servizioPosto?.data ?? ''}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={4}
                                md={4}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    required
                                    size='small'
                                    label={<FormattedMessage id="price" />}
                                    name="prezzo"
                                    onChange={handleChange}
                                    value={servizioPosto?.prezzo}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={4}
                                md={4}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    required
                                    size='small'
                                    label={<FormattedMessage id="rate" />}
                                    name="aliquota"
                                    onChange={handleChange}
                                    value={servizioPosto?.aliquota}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={4}
                                md={4}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    required
                                    size='small'
                                    label={<FormattedMessage id="quantity" />}
                                    name="quantita"
                                    onChange={handleChange}
                                    value={servizioPosto?.quantita}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={4}
                                md={4}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    disabled
                                    size='small'
                                    label={<FormattedMessage id="totalAmount" />}
                                    onChange={handleChange}
                                    value={getCurrencyByNumber(totalAmount)}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>)
            }
            <Divider />
            <Grid container>
                <Grid item
                    xs={12}
                    md={6}
                    lg={6}
                >
                    {
                        servizioPosto?.annuale && servizioPosto?.active &&
                        <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-start' }}>
                            <Button
                                color="warning"
                                type="submit"
                                variant="contained"
                                onClick={() => setSuspendDialogOpen(true)}
                            >
                                <FormattedMessage id="suspend" />
                            </Button>
                        </Box>
                    }
                </Grid>
                <Grid item
                    xs={12}
                    md={6}
                    lg={6}
                >
                    <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            onClick={servizioPostoUpdate}
                        >
                            <FormattedMessage id="save" />
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Card >
    );
}

export default ServizioPostoUpdate;