import { Box, Card, CardContent, CardHeader, keyframes, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PraticaService } from "services/PraticaServices";
import { UserPaymentsReport, defaultUserPaymentsReport } from "types/userPaymentsReport";
import PaymentsPage from "./PaymentsPage";
import CardContentLoader from "ui-component/CardContentLoader";
import ReportIcon from '@mui/icons-material/Report';
import PaymentsPageMobile from "./PaymentsPageMobile";
import { composeNotistackMessage, handleDownloadFile, isNotNullOrUndefined } from "utils/common";
import { FatturaService } from "services/FatturaServices";
import PaymentRedirectPage from "../Dossiers/PaymentRedirectPage";
import { PaymentRedirectData } from "types/paymentRedirectData";
import { PagamentoService } from "services/PagamentoServices";
import BackdropLoader from "ui-component/BackdropLoader";
import { PaymentStatus } from "types/enums/PaymentStatus";

const PaymentsList = () => {
    const praticaService = new PraticaService();
    const fatturaService = new FatturaService();
    const pagamentoService = new PagamentoService();

    const [placeServicesToPay, setPlaceServicesToPay] = useState<UserPaymentsReport>(defaultUserPaymentsReport)

    const [paymentRedirectData, setPaymentRedirectData] = useState<PaymentRedirectData | null>(null);
    const [isPaymentRedirectLoading, setIsPaymentRedirectLoading] = useState<boolean>(false);

    const intl = useIntl();

    useEffect(() => {
        setIsPageLoading(true);

        (async () => {
            await checkPaymentResult();

            const retrievedPlaceServicesToPay = await praticaService.GetPlaceServicesPaymentsReport();
            setPlaceServicesToPay(retrievedPlaceServicesToPay);

            setIsPageLoading(false);
        })()
    }, [])

    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

    var currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

    const blinkingBackgroundColor = keyframes`
        0% {
        background-color: #fa6b6b;
        }
        50% {
        background-color: #f54838;
        }
        100% {
        background-color: #fa6b6b;
        }
    `;

    const downloadFattura = async (fatturaId: string, fatturaName: string) => {
        const fatturaBase64: string = await fatturaService.DownloadFatturaById(fatturaId);
        handleDownloadFile(fatturaBase64, fatturaName);
    }

    //TODO: Ottimizzare e rimuovere ridondanza
    const updatePayment = async (paymentCode: string, payerFullName: string, paymentStatus: PaymentStatus) => {
        try {
            const paymentData = {
                paymentCode: paymentCode,
                payerFullName: payerFullName,
                paymentStatus: paymentStatus
            };

            await pagamentoService.UpdateUserPayment(paymentData);

            switch (paymentStatus) {
                case PaymentStatus.Rejected:
                    composeNotistackMessage(intl.formatMessage({ id: 'paymentfailed' }), 'error');
                    break;
                case PaymentStatus.Completed:
                    composeNotistackMessage(intl.formatMessage({ id: 'paymentCompleted' }), 'success');
                    break;
                default:
                    composeNotistackMessage(intl.formatMessage({ id: 'paymentCompletedButWarning' }), 'warning');
                    break;
            }
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        }
    }

    const checkPaymentResult = async () => {
        const url: string = window.location.href;

        let placeServiceId: string = url.split('placeServiceId=')[1] ?? '';
        if (placeServiceId !== '') {
            placeServiceId = placeServiceId.split('&')[0];
        }

        let nome: string = url.split('&nome=')[1] ?? '';
        if (nome !== '') {
            nome = nome.split('&')[0];
        }

        let cognome: string = url.split('&cognome=')[1] ?? '';
        if (cognome !== '') {
            cognome = cognome.split('&')[0];
        }

        let esito: string = url.split('&esito=')[1] ?? '';
        if (esito !== '') {
            esito = esito.split('&')[0];
        }

        let paymentCode: string = url.split('&codTrans=')[1] ?? '';
        if (paymentCode !== '') {
            paymentCode = paymentCode.split('&')[0];
            paymentCode = paymentCode.slice(0, -4);
        }

        const paymentCodeFromLocalStorage: string = localStorage.getItem('PaymentCode') ?? '';

        if (isNotNullOrUndefined(placeServiceId) && isNotNullOrUndefined(esito) && isNotNullOrUndefined(paymentCode)) {
            if (isNotNullOrUndefined(nome) && isNotNullOrUndefined(cognome)) {
                if (esito == 'OK') {
                    await updatePayment(paymentCode, `${nome} ${cognome}`, PaymentStatus.Completed);
                } else {
                    await updatePayment(paymentCode, `${nome} ${cognome}`, PaymentStatus.Rejected);
                }
            } else {
                await updatePayment(paymentCode, '', PaymentStatus.MissingUserData);
            }
        } else if (isNotNullOrUndefined(paymentCodeFromLocalStorage)) {
            await updatePayment(paymentCodeFromLocalStorage, '', PaymentStatus.MissingPaymentData);
        }

        localStorage.removeItem('PaymentCode');
    }

    const executePayment = async (amountToPay: string, placeServiceId: string) => {
        setIsPaymentRedirectLoading(true);

        try {
            const url: string = `/yourServices?placeServiceId=${placeServiceId}`;

            const retrievedPaymentRedirectData: PaymentRedirectData | null = await pagamentoService.ExecutePayment(amountToPay, url);

            localStorage.setItem("PaymentCode", retrievedPaymentRedirectData?.paymentCode ?? '');

            setPaymentRedirectData(retrievedPaymentRedirectData);
        } catch (e: any) {
            composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
        } finally {
            setIsPaymentRedirectLoading(false);
        }
    }

    const resetPaymentRedirectData = () => {
        setPaymentRedirectData(null);
    }

    return (
        <Card>
            <BackdropLoader open={isPaymentRedirectLoading} />

            <CardHeader title={<FormattedMessage id="servicesList" />} />
            {
                isPageLoading ?
                    <CardContentLoader /> :
                    <CardContent>
                        {
                            placeServicesToPay.toPay.length > 0 &&
                            <>
                                <Box sx={{
                                    borderRadius: "7px",
                                    padding: "7px",
                                    color: "black",
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    animation: `${blinkingBackgroundColor} 2s infinite`,
                                }}>
                                    <ReportIcon fontSize="small" sx={{ mr: "5px" }} />
                                    <FormattedMessage id="paymentsToDoWarningText" />
                                </Box>
                            </>
                        }
                        {
                            mobileDevice ?
                                <PaymentsPageMobile
                                    placeServices={placeServicesToPay}
                                    downloadFattura={downloadFattura}
                                    executePayment={executePayment}
                                /> :
                                <PaymentsPage
                                    placeServices={placeServicesToPay}
                                    downloadFattura={downloadFattura}
                                    executePayment={executePayment}
                                />
                        }
                    </CardContent>
            }
            {
                isNotNullOrUndefined(paymentRedirectData) &&
                <PaymentRedirectPage paymentRedirectData={paymentRedirectData!} resetPaymentRedirectData={resetPaymentRedirectData} />
            }
        </Card>
    )
}

export default PaymentsList;