import { DeleteTwoTone } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, CardHeader, Checkbox, Collapse, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { FC, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { StrutturaService } from "services/StrutturaServices";
import { VialeService } from "services/VialeServices";
import { ZonaService } from "services/ZonaServices";
import { Struttura, defaultStruttura } from "types/struttura";
import { Utente } from "types/utente";
import { Viale } from "types/viale";
import { Zona } from "types/zona";
import AlternateTableRow from "ui-component/AlternateTableRow";
import { composeNotistackMessage, getCurrencyByNumber, getFormValue, getShortDate, handleDownloadFile } from "utils/common";
import { Stecca } from "types/stecca";
import { SteccaService } from "services/SteccaServices";
import { PaginationQuery, defaultPaginationQuery } from "types/paginationQuery";
import SteccaUpsert from "pages/Stecche/SteccaUpsert";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircleIcon from '@mui/icons-material/Circle';
import { number, object, string } from "yup";
import { handleUpsertErrors } from "utils/validation";
import { useNavigate } from "react-router-dom";
import { Allegato } from "types/allegato";
import UsersList from "pages/Utenti/UsersList";
import PurpleStyledTableCell from "ui-component/PurpleStyledTableCell";
import VisuallyHiddenInput from "ui-component/VisuallyHiddenInput";
import DownloadIcon from '@mui/icons-material/Download';
import CardContentLoader from "ui-component/CardContentLoader";
import SteccaHandleComponent from "pages/Stecche/SteccaHandleComponent";
import React from "react";
import { Link as RouterLink } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DialogDeleteElement from "ui-component/DialogDeleteElement";
import TableRowsLoader from "ui-component/TableRowsLoader";
import BackdropLoader from "ui-component/BackdropLoader";
import dayjs from "dayjs";
import { tipiStruttura } from "types/enums/TipoStruttura";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { defaultSteccheInfo, SteccheInfo } from "types/steccheInfo";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ImageDropzone from "ui-component/ImageDropzone";

interface StrutturaUpsertProps {
	isAdd: boolean;
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

interface FileProps {
	id: string;
	name: string;
	base64: string;
}

const StructureUpsertTabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`structure-tabpanel-${index}`}
			aria-labelledby={`structure-tab-${index}`}
			{...other}
		>
			{value === index && (
				<>{children}</>
			)}
		</div>
	);
}

const StrutturaUpsert: FC<StrutturaUpsertProps> = (props) => {
	const { isAdd } = props;

	const intl = useIntl();

	const strutturaService = new StrutturaService();
	const vialeService = new VialeService();
	const zonaService = new ZonaService();
	const steccaService = new SteccaService();

	const [struttura, setStruttura] = useState<Struttura>(defaultStruttura);
	const [concessionari, setConcessionari] = useState<Array<Utente>>([]);

	const [structureImageBase64, setStructureImageBase64] = useState<string>('');

	let strutturaValidationSchema = object({
		denominazione: string().required('denominationRequired'),
		tipoStruttura: number().required('structureTypeRequired').typeError('structureTypeRequired')
	});

	const [steccheInfo, setSteccheInfo] = useState<SteccheInfo>(defaultSteccheInfo);
	const [stecchePaginationQuery, setStecchePaginationQuery] = useState<PaginationQuery>(defaultPaginationQuery);

	const [concessionariIds, setConcessionariIds] = useState<Array<string>>([]);

	const [viali, setViali] = useState<Viale[]>([]);
	const [zone, setZone] = useState<Zona[]>([]);

	const [tabIndex, setTabIndex] = useState<number>(0);

	const [openAssignUserModal, setOpenAssignUserModal] = useState<boolean>(false);
	const [openAddSteccaCollapse, setOpenAddSteccaCollapse] = useState<boolean>(false);

	const [files, setFiles] = useState<Array<FileProps>>([]);

	const [openAddSteccaModal, setOpenAddSteccaModal] = useState<boolean>(false);

	const navigate = useNavigate();

	const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

	const [steccaToHandle, setSteccaToHandle] = useState<Stecca | undefined>(undefined);

	var currentTheme = useTheme();
	const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

	useEffect(() => {
		setIsPageLoading(true);
		setIsConcessionariLoading(true);

		(async () => {
			setViali(await vialeService.GetUnpaginatedViali());
			setZone(await zonaService.GetUnpaginatedZone());

			if (!isAdd) {
				let strutturaId: string = window.location.href.split('strutturaId=')[1] ?? '';
				let retrievedStruttura: Struttura = await strutturaService.GetStrutturaById(strutturaId);

				let retrievedConcessionari: Array<Utente> = await strutturaService.GetStrutturaConcessionari(retrievedStruttura.id!);

				let retrievedAllegati: Array<Allegato> = await strutturaService.GetStrutturaAllegati(retrievedStruttura.id!);

				let retrievedStecche: SteccheInfo = await steccaService.GetSteccheByStrutturaId(stecchePaginationQuery, retrievedStruttura.id!);

				let retrievedAssignedUsers: Array<Utente> = await strutturaService.GetStrutturaAssignedUsers(retrievedStruttura.id!);

				setStructureImageBase64(retrievedStruttura.strutturaImage ?? '');

				setStruttura(retrievedStruttura);
				setStruttura((currentData: any) => ({ ...currentData, vialeId: retrievedStruttura.viale?.id, zonaId: retrievedStruttura.zona?.id }))

				setConcessionari(retrievedConcessionari);

				setRetrievedFiles(retrievedAllegati);

				setSteccheInfo(retrievedStecche);

				setAssignedUsers(retrievedAssignedUsers);
			}

			setIsPageLoading(false);
			setIsConcessionariLoading(false);
		})();
	}, [])

	useEffect(() => {
		setConcessionariIds(concessionari.map((concessionario) => concessionario.id!));
	}, [concessionari])

	const handleChange = (e: any) => {
		let { name, value } = getFormValue(e);
		setStruttura((currentFormData: any) => ({ ...currentFormData, [name]: value }));
	}

	const handleDateChange = (fieldName: string, date: any) => {
		let newValue: string | null;

		newValue = dayjs(date).format('YYYY-MM-DD');

		newValue = newValue == 'Invalid Date' ? null : newValue;

		setStruttura((currentFormData: any) => ({ ...currentFormData, [fieldName]: newValue }));
	}

	const strutturaUpsert = async () => {
		try {
			await strutturaValidationSchema.validate(struttura, { abortEarly: false });

			let strutturaId: string = await strutturaService.UpsertStruttura(struttura);

			if (isAdd) {
				setStruttura((prevStruttura) => ({ ...prevStruttura, id: strutturaId }));
				navigate(`/structures/edit?strutturaId=${strutturaId}`);
			}

			composeNotistackMessage(intl.formatMessage({ id: 'successfullySaved' }), 'success');
		} catch (validationErrors: any) {
			handleUpsertErrors(validationErrors.inner, intl);
		}
	}

	const addConcessionario = async (concessionarioId: string) => {
		setIsConcessionariLoading(true);

		(async () => {
			await strutturaService.AssignConcessionarioToStruttura({
				strutturaId: struttura.id,
				concessionarioId: concessionarioId
			});

			let retrievedConcessionari: Array<Utente> = await strutturaService.GetStrutturaConcessionari(struttura.id!);
			setConcessionari(retrievedConcessionari);

			setIsConcessionariLoading(false);
		})()
	}

	const updateStecche = () => {
		setIsSteccheLoading(true);

		(async () => {
			let retrievedStecche: SteccheInfo = await steccaService.GetSteccheByStrutturaId(stecchePaginationQuery, struttura.id!);

			setSteccheInfo(retrievedStecche);

			setIsSteccheLoading(false);
		})()
	}

	const updateSteccaToHandle = async (stecca: Stecca) => {
		setSteccaToHandle(stecca);
	}

	const deleteStecca = async (steccaId: string) => {
		setOpenAddSteccaCollapse(false);
		setSteccaToHandle(undefined);

		await steccaService.DeleteStecca(steccaId);

		updateStecche();
	}

	const deleteConcessionario = (concessionarioId: string) => {
		setIsConcessionariLoading(true);

		(async () => {
			await strutturaService.RemoveConcessionarioFromStruttura({
				strutturaId: struttura.id,
				concessionarioId: concessionarioId
			});

			setConcessionari(concessionari.filter((concessionario) => concessionario.id !== concessionarioId));

			setIsConcessionariLoading(false);
		})()
	}

	const a11yProps = (index: number) => {
		return {
			id: `structure-tab-${index}`,
			'aria-controls': `structure-tabpanel-${index}`,
		};
	}

	useEffect(() => {
		(async () => {
			if (struttura.id !== undefined) {
				let newRetrievedStecche = await steccaService.GetSteccheByStrutturaId(stecchePaginationQuery, struttura.id);
				setSteccheInfo(newRetrievedStecche);
			}
		})();
	}, [stecchePaginationQuery]);

	const setPageSize = (newPageSize: number) => {
		setStecchePaginationQuery((currentData: any) => ({ ...currentData, pageSize: newPageSize }))
	}

	const setPageNumber = (newPageNumber: number) => {
		setStecchePaginationQuery((currentData: any) => ({ ...currentData, pageNumber: newPageNumber }))
	}

	const [isLoadingFileUpload, setIsLoadingFileUpload] = useState<boolean>(false);

	const addDocument = async (newFileName: string, newFileBase64: string) => {
		setIsLoadingFileUpload(true);

		try {
			await strutturaService.InsertAllegatoToStruttura({
				strutturaId: struttura.id!,
				nomeFile: newFileName,
				allegatoBase64: newFileBase64
			});

			let retrievedAllegati: Array<Allegato> = await strutturaService.GetStrutturaAllegati(struttura.id!);

			setRetrievedFiles(retrievedAllegati);
		} catch (e: any) {

		} finally {
			setIsLoadingFileUpload(false);
		}
	}

	const handleUploadFile = async (e: any) => {
		var fileReader: FileReader = new FileReader();
		let newFileName = e.target.files[0].name;
		fileReader.readAsDataURL(e.target.files[0]);
		fileReader.onload = async () => {
			let newFileBase64: string = fileReader.result as string;
			await addDocument(newFileName, newFileBase64);
		}
	}

	const handleDeleteFile = async (allegatoId: string) => {
		await strutturaService.DeleteAllegatoFromStruttura(allegatoId);
		setFiles(files.filter((file) => file.id !== allegatoId));
	}

	const setRetrievedFiles = (retrievedAllegati: Allegato[]) => {
		setFiles(retrievedAllegati.map((retrievedAllegato) => {
			return {
				id: retrievedAllegato.id!,
				name: retrievedAllegato.nomeFile,
				base64: retrievedAllegato.allegatoBase64,
			}
		}));
	}

	const handleAddConcessionario = () => {
		let strutturaId: string = struttura.id!;
		navigate(`/users/create?strutturaId=${strutturaId}`);
	}

	const handleEditStecca = (stecca?: Stecca) => {
		setSteccaToHandle(undefined);
		setOpenAddSteccaCollapse(false);

		if (stecca?.id != steccaToHandle?.id) {
			setTimeout(() => {
				setSteccaToHandle(stecca);
				setOpenAddSteccaCollapse(true);
			}, 150);
		}
	}

	const [assignedUsers, setAssignedUsers] = useState<Array<Utente>>([]);
	const [openAssignUserCollapse, setOpenAssignUserCollapse] = useState<boolean>(false);

	const assignUser = async (userId: string) => {
		await strutturaService.AssignUserToStruttura({
			strutturaId: struttura.id!,
			userId: userId
		});
		await updateUsers();
	}

	const updateUsers = async () => {
		const retrievedUsers: Array<Utente> = await strutturaService.GetStrutturaAssignedUsers(struttura.id!);
		setAssignedUsers(retrievedUsers);
	}

	const removeStrutturaUser = async (userId: string) => {
		await strutturaService.DeleteUserFromStruttura({
			strutturaId: struttura.id!,
			userId: userId
		});
		await updateUsers();
	}

	const [deleteStrutturaDialogOpen, setDeleteStrutturaDialogOpen] = useState<boolean>(false);

	const deleteStruttura = async () => {
		try {
			await strutturaService.DeleteStruttura(struttura.id!);

			composeNotistackMessage(intl.formatMessage({ id: 'successfullyDelete' }), 'success');

			navigate('/structures');
		} catch (e: any) {
			composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
		}
	};

	const [isConcessionariLoading, setIsConcessionariLoading] = useState<boolean>(false);
	const [isSteccheLoading, setIsSteccheLoading] = useState<boolean>(false);

	const [entityToRemoveData, setEntityToRemoveData] = useState<{ id: string, name: string } | undefined>();

	const [removeOwnerDialogOpen, setRemoveOwnerDialogOpen] = useState<boolean>(false);
	const [removeDealerDialogOpen, setRemoveDealerDialogOpen] = useState<boolean>(false);

	const [removeAttachmentDialogOpen, setRemoveAttachmentDialogOpen] = useState<boolean>(false);

	const [deleteSteccaDialogOpen, setDeleteSteccaDialogOpen] = useState<boolean>(false);

	const downloadAllegato = async (allegatoId: string, allegatoName: string) => {
		const allegatoBase64: string = await strutturaService.DownloadStrutturaAllegato(allegatoId);
		handleDownloadFile(allegatoBase64, allegatoName);
	}

	const [fileInEditId, setFileInEditId] = useState<string>('');

	const [newFileName, setNewFileName] = useState<string>('');

	const handleCloseEditName = () => {
		setFileInEditId('');
		setNewFileName('');
	}

	const handleConfirmEditName = async () => {
		setIsPageLoading(true);

		try {
			await strutturaService.RenameAllegatoStruttura({
				allegatoId: fileInEditId,
				newAllegatoName: newFileName
			});

			composeNotistackMessage(intl.formatMessage({ id: 'fileRenamedSuccessfully' }), 'success');
			handleCloseEditName();

			let retrievedAllegati: Array<Allegato> = await strutturaService.GetStrutturaAllegati(struttura.id!);
			setRetrievedFiles(retrievedAllegati);

		} catch (e: any) {
			composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
		} finally {
			setIsPageLoading(false);
		}
	}

	const handleUpdateImage = async (base64: string) => {
		setStructureImageBase64(base64);
		setStruttura((currentData: Struttura) => ({ ...currentData, strutturaImage: base64 }))
	}

	const usersSurnameFilterRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		if ((openAssignUserCollapse || openAssignUserModal) && usersSurnameFilterRef.current) {
			usersSurnameFilterRef.current.focus();
		}
	}, [openAssignUserCollapse, openAssignUserModal]);

	return (
		<Card>
			<BackdropLoader open={isLoadingFileUpload} />

			<DialogDeleteElement
				open={deleteStrutturaDialogOpen}
				onClose={() => setDeleteStrutturaDialogOpen(false)}
				onCancel={() => setDeleteStrutturaDialogOpen(false)}
				onConfirm={() => {
					setDeleteStrutturaDialogOpen(false)
					deleteStruttura();
				}}
				entityToDeleteInfo={struttura.denominazione ?? ''}
				key="deleteStruttura"
			/>
			<DialogDeleteElement
				open={removeOwnerDialogOpen}
				onClose={() => setRemoveOwnerDialogOpen(false)}
				onCancel={() => setRemoveOwnerDialogOpen(false)}
				onConfirm={() => {
					setRemoveOwnerDialogOpen(false)
					removeStrutturaUser(entityToRemoveData!.id);
				}}
				entityToDeleteInfo={entityToRemoveData?.name ?? ''}
				customMessageIntlId="confirmOwnerRemove"
				key="removeOwner"
			/>
			<DialogDeleteElement
				open={removeDealerDialogOpen}
				onClose={() => setRemoveDealerDialogOpen(false)}
				onCancel={() => setRemoveDealerDialogOpen(false)}
				onConfirm={() => {
					setRemoveDealerDialogOpen(false)
					deleteConcessionario(entityToRemoveData!.id)
				}}
				entityToDeleteInfo={entityToRemoveData?.name ?? ''}
				customMessageIntlId="confirmDealerRemove"
				key="removeDealer"
			/>
			<DialogDeleteElement
				open={removeAttachmentDialogOpen}
				onClose={() => setRemoveAttachmentDialogOpen(false)}
				onCancel={() => setRemoveAttachmentDialogOpen(false)}
				onConfirm={() => {
					setRemoveAttachmentDialogOpen(false)
					handleDeleteFile(entityToRemoveData!.id)
				}}
				entityToDeleteInfo={entityToRemoveData?.name ?? ''}
				customMessageIntlId="confirmDeletion"
				key="removeAttachment"
			/>
			<DialogDeleteElement
				open={deleteSteccaDialogOpen}
				onClose={() => setDeleteSteccaDialogOpen(false)}
				onCancel={() => setDeleteSteccaDialogOpen(false)}
				onConfirm={() => {
					setDeleteSteccaDialogOpen(false)
					deleteStecca(entityToRemoveData!.id)
				}}
				entityToDeleteInfo={entityToRemoveData?.name ?? ''}
				customMessageIntlId="confirmDeletion"
				key="removeSlat"
			/>

			<CardHeader title={
				<Typography variant="h3">
					<FormattedMessage id="structure" />: {struttura.denominazione}
				</Typography>
			} />
			{
				isPageLoading ?
					<CardContentLoader /> :
					(
						<>
							<Tabs value={tabIndex} onChange={(event: any, newTabIndex: number) => { setTabIndex(newTabIndex) }} aria-label="Structures tabs" variant="scrollable" scrollButtons="auto">
								<Tab
									label={<FormattedMessage id="header" />}
									{...a11yProps(0)}
								/>
								<Tab
									label={<FormattedMessage id="slats" />}
									{...a11yProps(1)}
									disabled={isAdd}
								/>
								<Tab
									label={<FormattedMessage id="dealers" />}
									{...a11yProps(2)}
									disabled={isAdd || struttura.zona?.descrizione.toLowerCase().includes('colombario')}
								/>
								<Tab
									label={<FormattedMessage id="attachments" />}
									{...a11yProps(3)}
									disabled={isAdd || struttura.zona?.descrizione.toLowerCase().includes('colombario')}
								/>
							</Tabs>
							<CardContent>
								{/* TODO: Spostare ogni panel in un component a parte o capire come fare meglio. Sicuramente da sistemare */}
								<StructureUpsertTabPanel value={tabIndex} index={0}>
									{!isAdd &&
										<Accordion sx={{ mb: 1 }}>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls="intestatariStrutturaSummary"
												id="intestatariStrutturaSummary"
											>
												<Typography> <FormattedMessage id="showStructureHolders" /> </Typography>
											</AccordionSummary>
											<AccordionDetails>
												<Button variant="contained" sx={{ mb: 2 }} onClick={() => setOpenAssignUserCollapse(!openAssignUserCollapse)} startIcon={<PersonAddIcon />}>
													<FormattedMessage id={"assignUser"} />
												</Button>
												<Collapse in={openAssignUserCollapse}>
													<Box sx={{
														padding: "3px",
													}}>
														<UsersList
															fromExternal={true}
															alreadyAssignedUsersIds={assignedUsers.length > 0 ? assignedUsers.map(assignedUser => assignedUser.id!) : []}
															fromWhere="structureHeader"
															assignUserToEntity={assignUser}
															usersSurnameFilterRef={usersSurnameFilterRef}
														/>
													</Box>
												</Collapse>
												<TableContainer component={Paper}>
													<Table size="small">
														<TableHead>
															<TableRow>
																<TableCell align="right"> <FormattedMessage id="name" /></TableCell>
																<TableCell align="right"> <FormattedMessage id="surname" /></TableCell>
																<TableCell align="right"> <FormattedMessage id="actions" /></TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{
																assignedUsers.length > 0 &&
																assignedUsers &&
																assignedUsers.map((assignedUser: Utente) => (
																	<AlternateTableRow>
																		<TableCell align="right">{assignedUser.nome}</TableCell>
																		<TableCell align="right">{assignedUser.cognome}</TableCell>
																		<TableCell align="right">
																			<Tooltip title={<FormattedMessage id="edit" />} aria-label="Edit">
																				<IconButton component={RouterLink} to={`/users/edit?userId=${assignedUser.id}`}>
																					<ArrowForwardIosIcon fontSize="small" />
																				</IconButton>
																			</Tooltip>
																			<Tooltip title={<FormattedMessage id="delete" />} aria-label="Delete">
																				<IconButton onClick={() => {
																					setEntityToRemoveData({
																						id: assignedUser.id!,
																						name: `${assignedUser.nome} ${assignedUser.cognome}`
																					});
																					setRemoveOwnerDialogOpen(true);
																				}}>
																					<DeleteTwoTone fontSize="small" />
																				</IconButton>
																			</Tooltip>
																		</TableCell>
																	</AlternateTableRow>
																))
															}
														</TableBody>
													</Table>
												</TableContainer>
											</AccordionDetails>
										</Accordion>
									}

									<Box sx={{ marginBottom: '15px' }}>
										<ImageDropzone
											imageBase64={structureImageBase64}
											handleUpdateImage={handleUpdateImage}
										/>
									</Box>

									<Grid container spacing={1}>
										<Grid
											item
											lg={3}
											md={3}
											xs={12}
										>
											<TextField
												fullWidth
												size='small'
												label={<FormattedMessage id="number" />}
												name="numero"
												onChange={handleChange}
												value={struttura.numero}
											/>
										</Grid>
										<Grid
											item
											lg={5}
											md={5}
											xs={12}
										>
											<TextField
												fullWidth
												size='small'
												label={<FormattedMessage id="denomination" />}
												name="denominazione"
												onChange={handleChange}
												required
												value={struttura.denominazione}
											/>
										</Grid>
										<Grid
											item
											lg={2}
											md={2}
											xs={12}
										>
											<TextField
												fullWidth
												size='small'
												label={<FormattedMessage id="door" />}
												name="porta"
												onChange={handleChange}
												value={struttura.porta}
											/>
										</Grid>
										<Grid
											item
											lg={2}
											md={2}
											xs={6}
										>
											<FormControlLabel
												control={
													<Checkbox
														checked={struttura.strutturaSemplice}
													/>
												}
												label={<FormattedMessage id="simpleStructure" />}
												name="strutturaSemplice"
												labelPlacement="start"
												onChange={handleChange}
											/>
										</Grid>

										<Grid
											item
											lg={3}
											md={3}
											xs={12}
										>
											<FormControl fullWidth size='small' required>
												<InputLabel> <FormattedMessage id="structureType" /> </InputLabel>
												<Select
													name="tipoStruttura"
													label={<FormattedMessage id="structureType" />}
													onChange={handleChange}
													sx={{ color: 'white' }}
													value={struttura.tipoStruttura}
												>
													<MenuItem value={''}> <FormattedMessage id="selectStructureType" /> </MenuItem>
													{
														tipiStruttura
															.slice()
															.map((tipoStruttura) => (
																<MenuItem key={tipoStruttura.id} value={tipoStruttura.id}>
																	{tipoStruttura.name}
																</MenuItem>
															))
													}
												</Select>
											</FormControl>
										</Grid>
										<Grid
											item
											lg={4}
											md={4}
											xs={12}
										>
											<FormControl fullWidth size='small'>
												<InputLabel> <FormattedMessage id="block" /> </InputLabel>
												<Select
													name="vialeId"
													label={<FormattedMessage id="block" />}
													onChange={handleChange}
													sx={{ color: 'white' }}
													value={struttura.vialeId}
												>
													<MenuItem value={''}> <FormattedMessage id="selectBlock" /> </MenuItem>
													{
														viali && viali
															.slice()
															.map((viale) => (
																<MenuItem key={viale.id} value={viale.id}>
																	{viale.descrizione}
																</MenuItem>
															))
													}
												</Select>
											</FormControl>
										</Grid>
										<Grid
											item
											lg={3}
											md={3}
											xs={12}
										>
											<FormControl fullWidth size='small'>
												<InputLabel> <FormattedMessage id="zone" /> </InputLabel>
												<Select
													name="zonaId"
													label={<FormattedMessage id="zone" />}
													onChange={handleChange}
													sx={{ color: 'white' }}
													value={struttura.zonaId}
												>
													<MenuItem value={''}> <FormattedMessage id="selectZone" /> </MenuItem>
													{
														zone && zone
															.slice()
															.map((zona) => (
																<MenuItem key={zona.id} value={zona.id}>
																	{zona.descrizione}
																</MenuItem>
															))
													}
												</Select>
											</FormControl>
										</Grid>
										<Grid
											item
											lg={2}
											md={2}
											xs={6}
										>
											<FormControlLabel
												control={<Checkbox checked={struttura.monoStecca} />}
												label={<FormattedMessage id="monoStecca" />}
												name="monoStecca"
												labelPlacement="start"
												onChange={handleChange}
												value={struttura.monoStecca}
											/>
										</Grid>

										<Grid
											item
											lg={12}
											md={12}
											xs={12}
										>
											<TextField
												fullWidth
												multiline
												size='small'
												label={<FormattedMessage id="notes" />}
												name="note"
												inputProps={{
													style: {
														height: 50
													}
												}}
												onChange={handleChange}
												value={struttura.note}
											/>
										</Grid>

										<Grid
											item
											lg={12}
											md={12}
											xs={12}
										>
											<Accordion>
												<AccordionSummary
													expandIcon={<ExpandMoreIcon />}
													aria-controls="datiConcessioneSummary"
													id="datiConcessioneSummary"
												>
													<Typography> <FormattedMessage id="showMoreData" /> </Typography>
												</AccordionSummary>
												<AccordionDetails>
													<Grid container spacing={1}>
														<Grid
															item
															lg={4}
															md={4}
															xs={12}
														>
															<TextField
																fullWidth
																size='small'
																label={<FormattedMessage id="concessioneSuoloNumero" />}
																name="concessioneSuoloNumero"
																onChange={handleChange}
																value={struttura.concessioneSuoloNumero}
															/>
														</Grid>
														<Grid
															item
															lg={4}
															md={4}
															xs={12}
														>
															<TextField
																fullWidth
																size='small'
																label={<FormattedMessage id="concessioneSuoloNome" />}
																name="concessioneSuoloNome"
																onChange={handleChange}
																value={struttura.concessioneSuoloNome}
															/>
														</Grid>
														<Grid
															item
															lg={4}
															md={4}
															xs={12}
														>
															<DatePicker
																inputFormat="DD/MM/YYYY"
																label={<FormattedMessage id="concessioneSuoloData" />}
																onChange={(date: any) => {
																	handleDateChange('concessioneSuoloData', date);
																}}
																renderInput={(params) => (
																	<TextField fullWidth {...params} error={false} size='small' />
																)}
																value={struttura.concessioneSuoloData}
															/>
														</Grid>
														<Grid
															item
															lg={4}
															md={4}
															xs={12}
														>
															<TextField
																fullWidth
																size='small'
																label={<FormattedMessage id="concessioneEdiliziaNumero" />}
																name="concessioneEdiliziaNumero"
																onChange={handleChange}
																value={struttura.concessioneEdiliziaNumero}
															/>
														</Grid>
														<Grid
															item
															lg={4}
															md={4}
															xs={12}
														>
															<TextField
																fullWidth
																size='small'
																label={<FormattedMessage id="concessioneEdiliziaNome" />}
																name="concessioneEdiliziaNome"
																onChange={handleChange}
																value={struttura.concessioneEdiliziaNome}
															/>
														</Grid>
														<Grid
															item
															lg={4}
															md={4}
															xs={12}
														>
															<DatePicker
																inputFormat="DD/MM/YYYY"
																label={<FormattedMessage id="concessioneEdiliziaData" />}
																onChange={(date: any) => {
																	handleDateChange('concessioneEdiliziaData', date);
																}}
																renderInput={(params) => (
																	<TextField fullWidth {...params} error={false} size='small' />
																)}
																value={struttura.concessioneEdiliziaData}
															/>
														</Grid>
													</Grid>
												</AccordionDetails>
											</Accordion>
										</Grid>
									</Grid>
									<Grid container>
										<Grid item
											xs={6}
											md={6}
											lg={6}
										>
											{
												!isAdd &&
												<Box sx={{ pt: 1, display: 'flex', justifyContent: 'flex-start' }}>
													<Button
														color="error"
														type="submit"
														variant="contained"
														onClick={() => setDeleteStrutturaDialogOpen(true)}
														startIcon={<DeleteIcon />}
													>
														<FormattedMessage id="delete" />
													</Button>
												</Box>
											}

										</Grid>
										<Grid item
											xs={6}
											md={6}
											lg={6}
										>
											<Box sx={{ pt: 1, display: 'flex', justifyContent: 'flex-end' }}>
												<Button
													color="primary"
													type="submit"
													variant="contained"
													onClick={strutturaUpsert}
													startIcon={<SaveIcon />}
												>
													<FormattedMessage id="save" />
												</Button>
											</Box>
										</Grid>
									</Grid>
								</StructureUpsertTabPanel>
								<StructureUpsertTabPanel value={tabIndex} index={1}>
									<Grid container direction="row" sx={{ mb: 3 }}>
										<Grid item>
											<Button variant="contained" onClick={() => setOpenAddSteccaModal(!openAddSteccaModal)} startIcon={<AddIcon />}>
												<FormattedMessage id="addSlat" />
											</Button>
										</Grid>
									</Grid>

									<Collapse in={openAddSteccaModal}>
										<SteccaUpsert isAdd={true} strutturaId={struttura.id!} updateStecche={updateStecche} />
									</Collapse>

									{
										steccheInfo.stecche && steccheInfo.stecche.items?.map((stecca: Stecca, index: number) => (
											<>
												<Grid container
													sx={{
														backgroundColor: stecca.id == steccaToHandle?.id ? 'lightgray' : (index % 2 === 0 ? '#F0F0F0' : '#FFFFFF'),
														p: 2,
														overflow: 'hidden',
														cursor: 'pointer'
													}}
													alignItems="center"
													onClick={() => handleEditStecca(stecca)}
												>
													<Grid
														item
														lg={6}
														md={6}
														xs={12}
													>
														<Grid
															container
															direction="row"
															alignItems="center"
														>
															<Grid
																item
																xl={4}
																lg={4}
																md={4}
																xs={4}
															>
																<Tooltip title={stecca.denominazione} aria-label="slat name">
																	<Typography sx={!mobileDevice ? { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } : {}}>
																		{stecca.denominazione}
																	</Typography>
																</Tooltip>
															</Grid>
															<Grid
																item
																xl={8}
																lg={8}
																md={8}
																xs={8}
															>
																<Grid container direction="row" sx={{ display: 'flex', justifyContent: mobileDevice ? 'flex-start' : 'center' }}>
																	<Grid item sx={{ width: 40, marginX: 1 }}>
																		<Tooltip title={
																			<Box>
																				<FormattedMessage id="freePlaces" />
																				{
																					stecca.infoPostiLiberi !== undefined && stecca.infoPostiLiberi.map((rowInfo: string) => <Typography>{rowInfo}</Typography>)
																				}
																			</Box>
																		} enterTouchDelay={0}>
																			<Stack alignItems="center" direction="row" sx={{ mr: 1 }}>
																				<CircleIcon fontSize="small" sx={{ mr: "4px", color: "#5fb062" }} /> {stecca.numPostiLiberi ?? stecca.numColonne * stecca.numRighe}
																			</Stack>
																		</Tooltip>
																	</Grid>
																	<Grid item sx={{ width: 40, marginX: 1 }}>
																		<Tooltip title={
																			<Box>
																				<FormattedMessage id="placesWithDealer" />
																				{
																					stecca.infoPostiConcessionari !== undefined && stecca.infoPostiConcessionari.map((rowInfo: string) => <Typography>{rowInfo}</Typography>)
																				}
																			</Box>
																		} enterTouchDelay={0}>
																			<Stack alignItems="center" direction="row" sx={{ mr: 1 }}>
																				<CircleIcon fontSize="small" sx={{ mr: "4px", color: "#FFAE35" }} /> {stecca.numPostiConcessionari ?? 0}
																			</Stack>
																		</Tooltip>
																	</Grid>
																	<Grid item sx={{ width: 40, marginX: 1 }}>
																		<Tooltip title={
																			<Box>
																				<FormattedMessage id="placesWithEntitled" />
																				{
																					stecca.infoPostiAventiDiritto !== undefined && stecca.infoPostiAventiDiritto.map((rowInfo: string) => <Typography>{rowInfo}</Typography>)
																				}
																			</Box>
																		} enterTouchDelay={0}>
																			<Stack alignItems="center" direction="row" sx={{ mr: 1 }}>
																				<CircleIcon fontSize="small" sx={{ mr: "4px", color: "#FFFF53" }} /> {stecca.numPostiAventiDiritto ?? 0}
																			</Stack>
																		</Tooltip>
																	</Grid>
																	<Grid item sx={{ width: 40, marginX: 1 }}>
																		<Tooltip title={
																			<Box>
																				<FormattedMessage id="placesWithReferent" />
																				{
																					stecca.infoPostiReferenti !== undefined && stecca.infoPostiReferenti.map((rowInfo: string) => <Typography>{rowInfo}</Typography>)
																				}
																			</Box>
																		} enterTouchDelay={0}>
																			<Stack alignItems="center" direction="row" sx={{ mr: 1 }}>
																				<CircleIcon fontSize="small" sx={{ mr: "4px", color: "#69B4FF" }} /> {stecca.numPostiReferenti ?? 0}
																			</Stack>
																		</Tooltip>
																	</Grid>
																	<Grid item sx={{ width: 40, marginX: 1 }}>
																		<Tooltip title={
																			<Box>
																				<FormattedMessage id="busyPlaces" />
																				{
																					stecca.infoPostiSalme !== undefined && stecca.infoPostiSalme.map((rowInfo: string) => <Typography>{rowInfo}</Typography>)
																				}
																			</Box>
																		} enterTouchDelay={0}>
																			<Stack alignItems="center" direction="row">
																				<CircleIcon fontSize="small" sx={{ mr: "4px", color: "#FF4242" }} /> {stecca.numPostiSalme ?? 0}
																			</Stack>
																		</Tooltip>
																	</Grid>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
													<Grid
														item
														lg={5}
														md={5}
														xs={12}
														sx={{ mt: mobileDevice ? 3 : 0 }}
													>
														<Grid
															container
															spacing={2}
														>
															<Grid
																item
																lg={6}
																md={6}
																xs={6}
															>
																<Stack direction="row" sx={{ width: '80%' }}>
																	<Typography><b><FormattedMessage id="earnable" /></b>:</Typography>

																	<Box sx={{ flexGrow: 1, textAlign: 'right' }}>
																		<Typography>{getCurrencyByNumber(stecca.guadagnabile)}</Typography>
																	</Box>
																</Stack>
															</Grid>
															<Grid
																item
																lg={6}
																md={6}
																xs={6}
															>
																<Stack direction="row" sx={{ width: '80%' }}>
																	<Typography><b> <FormattedMessage id="earned" /></b>:</Typography>

																	<Box sx={{ flexGrow: 1, textAlign: 'right' }}>
																		<Typography>{getCurrencyByNumber(stecca.guadagnato)} </Typography>
																	</Box>
																</Stack>
															</Grid>
														</Grid>
													</Grid>
													<Grid
														item
														lg={1}
														md={1}
														xs={12}
														sx={{ mt: mobileDevice ? 3 : 0 }}
													>
														<Grid
															container
															direction="row"
															display="flex"
															justifyContent="end"
														>
															<Grid item
																lg={4}
																md={4}
																xs={4}
															>
																<IconButton>
																	{stecca.id == steccaToHandle?.id ?
																		<KeyboardArrowUpIcon /> :
																		<KeyboardArrowDownIcon />
																	}
																</IconButton>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
												{
													stecca.id == steccaToHandle?.id &&
													<Collapse in={openAddSteccaCollapse}>
														<Box sx={{ m: 2 }}>
															<SteccaHandleComponent stecca={steccaToHandle} updateStecche={updateStecche} updateSteccaToHandle={updateSteccaToHandle} isLoading={isSteccheLoading} deleteStecca={deleteStecca} />
														</Box>
													</Collapse>
												}
											</>
										))
									}

									<Grid container
										sx={{
											backgroundColor: '#e6e3e3',
											p: 2,
											overflow: 'hidden',
											mt: 8
										}}
										alignItems="center"
									>
										<Grid
											item
											lg={6}
											md={6}
											xs={12}
										>
											<Grid
												container
												direction="row"
												alignItems="center"
											>
												<Grid
													item
													xl={4}
													lg={4}
													md={4}
													xs={4}
												>
													<Tooltip title={<FormattedMessage id="total" />} aria-label="slat name">
														<Typography sx={!mobileDevice ? { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } : {}}>
															<b><FormattedMessage id="total" /></b>
														</Typography>
													</Tooltip>
												</Grid>
												<Grid
													item
													xl={8}
													lg={8}
													md={8}
													xs={8}
												>
													<Grid container direction="row" sx={{ display: 'flex', justifyContent: mobileDevice ? 'flex-start' : 'center' }}>
														<Grid item sx={{ width: 40, marginX: 1 }}>
															<Tooltip title={<FormattedMessage id="freePlaces" />}>
																<Stack alignItems="center" direction="row" sx={{ mr: 1 }}>
																	<CircleIcon fontSize="small" sx={{ mr: "4px", color: "#5fb062" }} /> {steccheInfo.totNumPostiLiberi}
																</Stack>
															</Tooltip>
														</Grid>

														<Grid item sx={{ width: 40, marginX: 1 }}>
															<Tooltip title={<FormattedMessage id="placesWithDealer" />}>
																<Stack alignItems="center" direction="row" sx={{ mr: 1 }}>
																	<CircleIcon fontSize="small" sx={{ mr: "4px", color: "#FFAE35" }} /> {steccheInfo.totNumPostiConcessionari}
																</Stack>
															</Tooltip>
														</Grid>

														<Grid item sx={{ width: 40, marginX: 1 }}>
															<Tooltip title={<FormattedMessage id="placesWithEntitled" />}>
																<Stack alignItems="center" direction="row" sx={{ mr: 1 }}>
																	<CircleIcon fontSize="small" sx={{ mr: "4px", color: "#FFFF53" }} /> {steccheInfo.totNumPostiAventiDiritto}
																</Stack>
															</Tooltip>
														</Grid>

														<Grid item sx={{ width: 40, marginX: 1 }}>
															<Tooltip title={<FormattedMessage id="placesWithReferent" />}>
																<Stack alignItems="center" direction="row" sx={{ mr: 1 }}>
																	<CircleIcon fontSize="small" sx={{ mr: "4px", color: "#69B4FF" }} /> {steccheInfo.totNumPostiReferenti}
																</Stack>
															</Tooltip>
														</Grid>

														<Grid item sx={{ width: 40, marginX: 1 }}>
															<Tooltip title={<FormattedMessage id="busyPlaces" />}>
																<Stack alignItems="center" direction="row">
																	<CircleIcon fontSize="small" sx={{ mr: "4px", color: "#FF4242" }} /> {steccheInfo.totNumPostiSalme}
																</Stack>
															</Tooltip>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid
											item
											lg={5}
											md={5}
											xs={12}
											sx={{ mt: mobileDevice ? 3 : 0 }}
										>
											<Grid
												container
											>
												<Grid
													item
													lg={6}
													md={6}
													xs={6}
												>
													<Stack direction="row" sx={{ width: '80%' }}>
														<Typography><b><FormattedMessage id="earnable" /></b>:</Typography>

														<Box sx={{ flexGrow: 1, textAlign: 'right' }}>
															<Typography>{getCurrencyByNumber(steccheInfo.totGuadagnabile)}</Typography>
														</Box>
													</Stack>
												</Grid>
												<Grid
													item
													lg={6}
													md={6}
													xs={6}
												>
													<Stack direction="row" sx={{ width: '80%' }}>
														<Typography><b><FormattedMessage id="earned" /></b>:</Typography>

														<Box sx={{ flexGrow: 1, textAlign: 'right' }}>
															<Typography>{getCurrencyByNumber(steccheInfo.totGuadagnato)}</Typography>
														</Box>
													</Stack>
												</Grid>
											</Grid>
										</Grid>
									</Grid>

									<Grid container justifyContent="flex-end">
										<TablePagination
											component="div"
											count={steccheInfo.stecche.totalCount}
											onRowsPerPageChange={(e) => {
												setPageSize(parseInt(e.target.value, 10));
											}}
											onPageChange={(e, page) => {
												setPageNumber(page);
											}}
											page={stecchePaginationQuery.pageNumber}
											rowsPerPage={stecchePaginationQuery.pageSize}
											rowsPerPageOptions={[10, 25, 50, 100]}
											labelRowsPerPage={<FormattedMessage id={mobileDevice ? "rows" : "rowsPerPage"} />}
											labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'of' })} ${count}`}
										/>
									</Grid>

								</StructureUpsertTabPanel>
								<StructureUpsertTabPanel value={tabIndex} index={2}>
									<Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
										<Grid item>
											<Button variant="contained" onClick={() => setOpenAssignUserModal(!openAssignUserModal)} startIcon={<PersonAddIcon />}>
												<FormattedMessage id="assignDealer" />
											</Button>
										</Grid>
									</Grid>
									<Collapse in={openAssignUserModal}>
										<Box sx={{
											padding: "5px",
											overflowY: 'auto',
										}}>
											<UsersList
												fromExternal={true}
												fromWhere="structure"
												alreadyAssignedUsersIds={concessionariIds}
												assignUserToEntity={addConcessionario}
												customAddMethod={handleAddConcessionario}
												usersSurnameFilterRef={usersSurnameFilterRef}
											/>
										</Box>
									</Collapse>
									<TableContainer component={Paper}>
										<Table>
											<TableHead>
												<TableRow>
													<PurpleStyledTableCell> <FormattedMessage id="owner" /> </PurpleStyledTableCell>
													<PurpleStyledTableCell> <FormattedMessage id="residenceMunicipality" /> </PurpleStyledTableCell>
													<PurpleStyledTableCell> <FormattedMessage id="birthDate" /> </PurpleStyledTableCell>
													<PurpleStyledTableCell> <FormattedMessage id="actions" /> </PurpleStyledTableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{
													isConcessionariLoading ?
														<TableRowsLoader rowsNum={3} colsNum={4} /> :
														concessionari && concessionari?.map((concessionario) => (
															<AlternateTableRow key={concessionario.id!}>
																<TableCell> {concessionario.nome} {concessionario.cognome} </TableCell>
																<TableCell> {concessionario.comune} </TableCell>
																<TableCell> {getShortDate(concessionario.dataNascita)} </TableCell>
																<TableCell>
																	<Tooltip title={<FormattedMessage id="delete" />} aria-label="Delete">
																		<IconButton onClick={() => {
																			setEntityToRemoveData({
																				id: concessionario.id!,
																				name: `${concessionario.nome} ${concessionario.cognome}`
																			});
																			setRemoveDealerDialogOpen(true);
																		}}>
																			<DeleteTwoTone fontSize="small" />
																		</IconButton>
																	</Tooltip>
																</TableCell>
															</AlternateTableRow>
														))
												}
											</TableBody>
										</Table>
									</TableContainer>
								</StructureUpsertTabPanel>
								<StructureUpsertTabPanel value={tabIndex} index={3}>
									<Grid
										container
										direction="row"
									>
										<Grid item lg={4}>
											<Button component="label" variant="contained" startIcon={<AttachFileIcon />}>
												<FormattedMessage id="uploadFile" />
												<VisuallyHiddenInput type="file" onChange={handleUploadFile} />
											</Button>
										</Grid>
									</Grid>
									<Grid
										container
										direction="column"
									>
										{files && files?.map((file) => (
											<Grid container direction="row" sx={{ mt: 2 }} columnSpacing={2}>
												<Grid item
													xs={1}
													md={1}
													xl={1}
												>
													<Tooltip title={<FormattedMessage id="rename" />} aria-label="rename">
														{
															fileInEditId !== file.id ?
																<IconButton edge="end" onClick={() => { setFileInEditId(file.id); setNewFileName(file.name) }}>
																	<EditIcon fontSize="small" />
																</IconButton> :
																<>
																	<IconButton edge="end" onClick={handleCloseEditName} sx={{ mr: 1 }}>
																		<CloseIcon fontSize="small" />
																	</IconButton>
																	<IconButton edge="end" onClick={handleConfirmEditName}>
																		<CheckIcon fontSize="small" />
																	</IconButton>
																</>
														}
													</Tooltip>
												</Grid>
												<Grid item
													xs={4}
													md={4}
													xl={4}
												>
													<Tooltip title={fileInEditId !== file.id ? file.name : newFileName}>
														<TextField
															size="small"
															value={fileInEditId !== file.id ? file.name : newFileName}
															disabled={fileInEditId !== file.id}
															onChange={(e) => { setNewFileName(e.target.value) }}
															fullWidth
														/>
													</Tooltip>
												</Grid>
												<Grid item
													xs={4}
													md={4}
													xl={4}
												>
													<Tooltip title={<FormattedMessage id="delete" />} aria-label="Delete" sx={{ mr: 1 }}>
														<IconButton edge="end" onClick={() => {
															setEntityToRemoveData({
																id: file.id,
																name: file.name
															});
															setRemoveAttachmentDialogOpen(true);
														}}>
															<DeleteTwoTone />
														</IconButton>
													</Tooltip>

													<Tooltip title={<FormattedMessage id="download" />} aria-label="download">
														<IconButton edge="end" onClick={() => downloadAllegato(file.id, file.name)}>
															<DownloadIcon fontSize="small" />
														</IconButton>
													</Tooltip>
												</Grid>
											</Grid>
										))}
									</Grid>
								</StructureUpsertTabPanel>
							</CardContent>
						</>
					)
			}
		</Card >
	)
};

export default StrutturaUpsert;
