import { Accordion, AccordionSummary, AccordionDetails, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, Box } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { UserPaymentsReport } from "types/userPaymentsReport";
import AlternateTableRow from "ui-component/AlternateTableRow";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PlaceServicePaymentsReport } from "types/placeServicePaymentsReport";
import { getShortDate, getWithTwoDecimal } from "utils/common";
import { IconFiles } from "@tabler/icons";
import { Link as RouterLink } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

interface PaymentsPageProps {
    placeServices: UserPaymentsReport;
    downloadFattura: (fatturaId: string, fatturaName: string) => void;
    executePayment: (amountToPay: string, placeServiceId: string) => void;
}

const PaymentsPage: FC<PaymentsPageProps> = (props) => {
    const { placeServices, downloadFattura, executePayment } = props;

    return <>
        <Accordion defaultExpanded={placeServices.toPay.length > 0}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="toPaySummary"
                id="toPaySummary"
            >
                <h3> <FormattedMessage id="toPay" /> ({placeServices.toPay.length}) </h3>
            </AccordionSummary>
            <AccordionDetails>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left"> <FormattedMessage id="dossier" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="structure" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="placeNumber" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="description" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="date" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="year" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="totalAmount" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="totalPayed" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="invoiceNumber" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="actions" /></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                placeServices &&
                                placeServices.toPay &&
                                placeServices.toPay.length > 0 &&
                                placeServices.toPay.map((placeService: PlaceServicePaymentsReport) => (
                                    <AlternateTableRow>
                                        <TableCell align="left">{placeService.dossierNumber}</TableCell>
                                        <TableCell align="left">{placeService.structureDenomination}</TableCell>
                                        <TableCell align="left">{placeService.placeNumber}</TableCell>
                                        <TableCell align="left">{placeService.placeServiceDescription}</TableCell>
                                        <TableCell align="left">{placeService.placeServiceDate}</TableCell>
                                        <TableCell align="left">{placeService.year}</TableCell>
                                        <TableCell align="left">{placeService.placeServiceTotalAmount}</TableCell>
                                        <TableCell align="left">{placeService.placeServiceTotalPayed}</TableCell>
                                        <TableCell align="left">
                                            {
                                                placeService.invoiceId !== undefined && placeService.invoiceId !== null &&
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    <b>{placeService.invoiceNumber}</b>
                                                    <IconButton onClick={() => downloadFattura(placeService.invoiceId, `invoice-${placeService.invoiceNumber}.pdf`)}>
                                                        <DownloadIcon fontSize="small" />
                                                    </IconButton>
                                                </Box>
                                            }
                                        </TableCell>
                                        <TableCell align="left">
                                            <Tooltip title={<FormattedMessage id="pay" />} aria-label="Pay">
                                                <IconButton onClick={() => {
                                                    executePayment(getWithTwoDecimal(placeService.placeServiceTotalAmount - placeService.placeServiceTotalPayed), placeService.placeServiceId);
                                                }}>
                                                    <AttachMoneyIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </AlternateTableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="payedSummary"
                id="payedSummary"
            >
                <h3> <FormattedMessage id="payed" /> ({placeServices.payed.length}) </h3>
            </AccordionSummary>
            <AccordionDetails>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left"> <FormattedMessage id="dossier" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="structure" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="placeNumber" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="description" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="date" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="totalAmount" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="totalPayed" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="invoiceNumber" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="actions" /></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                placeServices &&
                                placeServices.payed &&
                                placeServices.payed.length > 0 &&
                                placeServices.payed.map((placeService: PlaceServicePaymentsReport) => (
                                    <AlternateTableRow>
                                        <TableCell align="left">{placeService.dossierNumber}</TableCell>
                                        <TableCell align="left">{placeService.structureDenomination}</TableCell>
                                        <TableCell align="left">{placeService.placeNumber}</TableCell>
                                        <TableCell align="left">{placeService.placeServiceDescription}</TableCell>
                                        <TableCell align="left">{getShortDate(placeService.placeServiceDate)}</TableCell>
                                        <TableCell align="left">{placeService.placeServiceTotalAmount}</TableCell>
                                        <TableCell align="left">{placeService.placeServiceTotalPayed}</TableCell>
                                        <TableCell align="left">
                                            {
                                                placeService.invoiceId !== undefined && placeService.invoiceId !== null &&
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    <b>{placeService.invoiceNumber}</b>
                                                    <IconButton onClick={() => downloadFattura(placeService.invoiceId, `invoice-${placeService.invoiceNumber}.pdf`)}>
                                                        <DownloadIcon fontSize="small" />
                                                    </IconButton>
                                                </Box>
                                            }
                                        </TableCell>
                                        <TableCell align="left">
                                            <Tooltip title={<FormattedMessage id="goToDossier" />} aria-label="Vai alle pratiche">
                                                <IconButton component={RouterLink} to={`/yourDossiers/info?dossierId=${placeService.dossierId}`}>
                                                    <IconFiles fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </AlternateTableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="paymentToVerify"
                id="paymentToVerify"
            >
                <h3> <FormattedMessage id="paymentsToVerify" /> ({placeServices.paymentsToVerify.length}) </h3>
            </AccordionSummary>
            <AccordionDetails>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left"> <FormattedMessage id="dossier" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="structure" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="placeNumber" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="description" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="date" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="totalAmount" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="totalPayed" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="invoiceNumber" /></TableCell>
                                <TableCell align="left"> <FormattedMessage id="actions" /></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                placeServices &&
                                placeServices.paymentsToVerify &&
                                placeServices.paymentsToVerify.length > 0 &&
                                placeServices.paymentsToVerify.map((placeService: PlaceServicePaymentsReport) => (
                                    <AlternateTableRow>
                                        <TableCell align="left">{placeService.dossierNumber}</TableCell>
                                        <TableCell align="left">{placeService.structureDenomination}</TableCell>
                                        <TableCell align="left">{placeService.placeNumber}</TableCell>
                                        <TableCell align="left">{placeService.placeServiceDescription}</TableCell>
                                        <TableCell align="left">{getShortDate(placeService.placeServiceDate)}</TableCell>
                                        <TableCell align="left">{placeService.placeServiceTotalAmount}</TableCell>
                                        <TableCell align="left">{placeService.placeServiceTotalPayed}</TableCell>
                                        <TableCell align="left">
                                            {
                                                placeService.invoiceId !== undefined && placeService.invoiceId !== null &&
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    <b>{placeService.invoiceNumber}</b>
                                                    <IconButton onClick={() => downloadFattura(placeService.invoiceId, `invoice-${placeService.invoiceNumber}.pdf`)}>
                                                        <DownloadIcon fontSize="small" />
                                                    </IconButton>
                                                </Box>
                                            }
                                        </TableCell>
                                        <TableCell align="left">
                                            <Tooltip title={<FormattedMessage id="goToDossier" />} aria-label="Vai alle pratiche">
                                                <IconButton component={RouterLink} to={`/yourDossiers/info?dossierId=${placeService.dossierId}`}>
                                                    <IconFiles fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </AlternateTableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
        </Accordion>
    </>;
}

export default PaymentsPage;