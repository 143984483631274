import { Box, Button, Card, CardContent, CardHeader, Checkbox, Dialog, DialogActions, DialogTitle, Divider, FormControlLabel, Grid, IconButton, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ServizioService } from "services/ServizioServices";
import { Servizio, defaultServizio } from "types/servizio";
import CardContentLoader from "ui-component/CardContentLoader";
import DialogDeleteElement from "ui-component/DialogDeleteElement";
import { composeNotistackMessage, getFormValue, isNotNullOrUndefined } from "utils/common";
import { handleUpsertErrors } from "utils/validation";
import { number, object, string } from "yup";
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

interface ServizioUpsertProps {
	isAdd: boolean;
}

const ServizioUpsert: FC<ServizioUpsertProps> = (props) => {
	const { isAdd } = props;

	const intl = useIntl();

	const servizioService = new ServizioService();
	const [servizio, setServizio] = useState<Servizio>(defaultServizio);

	let servizioValidationSchema = object({
		descrizione: string().required('descriptionRequired'),
		prezzo: number().required('priceRequired').typeError('priceMustBeNumber'),
		aliquota: number().required('rateRequired').typeError('rateMustBeNumber'),
	});

	const navigate = useNavigate();

	const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

	useEffect(() => {
		setIsPageLoading(true);

		(async () => {
			if (!isAdd) {
				let servizioId: string = window.location.href.split('serviceId=')[1] ?? '';
				let retrievedServizio: Servizio = await servizioService.GetServizioById(servizioId);
				setServizio(retrievedServizio);
			}
			setIsPageLoading(false);
		})();
	}, [])

	const handleChange = (e: any) => {
		let { name, value } = getFormValue(e);
		setServizio((currentFormData: any) => ({ ...currentFormData, [name]: value }));

		if (name === 'prezzo' || name === 'aliquota') {
			setChangedPriceVatRate(true);
		}
	}

	const servizioUpsert = async () => {
		try {
			await servizioValidationSchema.validate(servizio, { abortEarly: false });

			await servizioService.UpsertServizio(servizio, newPriceServiceDate);

			composeNotistackMessage(intl.formatMessage({ id: 'successfullySaved' }), 'success');

			setNewPriceServiceStartOpen(false);
			setChangedPriceVatRate(false);
			setNewPriceServiceDate(null);

			if (isAdd) navigate('/services');
		} catch (validationErrors: any) {
			handleUpsertErrors(validationErrors.inner, intl);
		}
	}

	const [deleteServizioDialogOpen, setDeleteServizioDialogOpen] = useState<boolean>(false);

	const deleteServizio = async () => {
		try {
			await servizioService.DeleteServizioById(servizio.id!);

			composeNotistackMessage(intl.formatMessage({ id: 'successfullyDelete' }), 'success');

			navigate('/services');
		} catch (e: any) {
			composeNotistackMessage(intl.formatMessage({ id: 'generalError' }), 'error');
		}
	};

	const [changedPriceVatRate, setChangedPriceVatRate] = useState<boolean>(false);
	const [newPriceServiceStartOpen, setNewPriceServiceStartOpen] = useState<boolean>(false);
	const [newPriceServiceDate, setNewPriceServiceDate] = useState<string | null>(null);

	const checkIfPriceOrVatRateChanged = async () => {
		if (changedPriceVatRate) {
			setNewPriceServiceStartOpen(true);
		} else {
			await servizioUpsert();
		}
	}

	const handleDateChange = (date: any) => {
		let newValue: string | null;

		newValue = dayjs(date).format('YYYY-MM-DD');

		newValue = newValue == 'Invalid Date' ? null : newValue;

		setNewPriceServiceDate(newValue);
	}

	return (
		<Card>
			<DialogDeleteElement
				open={deleteServizioDialogOpen}
				onClose={() => setDeleteServizioDialogOpen(false)}
				onCancel={() => setDeleteServizioDialogOpen(false)}
				onConfirm={() => {
					setDeleteServizioDialogOpen(false)
					deleteServizio();
				}}
				entityToDeleteInfo={servizio.descrizione}
			/>
			<CardHeader title={<FormattedMessage id="service" />} />
			<Divider />

			<Dialog
				open={newPriceServiceStartOpen}
				onClose={() => setNewPriceServiceStartOpen(false)}
				aria-labelledby="change-price-title"
				aria-describedby="change-price-description"
				fullWidth
				maxWidth="md"
			>
				<DialogTitle id="change-price-place-service">
					<FormattedMessage id="choosePlaceServiceStartDatePrice" />
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={() => setNewPriceServiceStartOpen(false)}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				<Box sx={{ mx: 3 }}>
					<DatePicker
						inputFormat="MM"
						views={['month']}
						label={<FormattedMessage id="date" />}
						minDate={new Date(new Date().setDate(new Date().getDate() - 15))}
						maxDate={new Date(new Date().setDate(new Date().getDate() + 31))}
						onChange={(date: any) => {
							handleDateChange(date);
						}}
						renderInput={(params) => (
							<TextField fullWidth {...params} error={false} size='small' />
						)}
						value={newPriceServiceDate}
					/>
				</Box>
				<DialogActions>
					<Button variant="contained" color="error" onClick={() => setNewPriceServiceStartOpen(false)}><FormattedMessage id="cancel" /></Button>
					<Button variant="contained" onClick={servizioUpsert} disabled={!isNotNullOrUndefined(newPriceServiceDate)}><FormattedMessage id="confirm" /></Button>
				</DialogActions>
			</Dialog>

			{
				isPageLoading ?
					<CardContentLoader /> :
					(
						<CardContent>
							<Grid container direction="row" spacing={1}>
								<Grid
									item
									lg={4}
									md={4}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="code" />}
										name="codice"
										onChange={handleChange}
										value={servizio.codice}
									/>
								</Grid>
								<Grid
									item
									lg={4}
									md={4}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="description" />}
										name="descrizione"
										required
										onChange={handleChange}
										value={servizio.descrizione}
									/>
								</Grid>
								<Grid
									item
									lg={4}
									md={4}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="price" />}
										name="prezzo"
										required
										onChange={handleChange}
										value={servizio.prezzo}
									/>
								</Grid>
								<Grid
									item
									lg={4}
									md={4}
									xs={12}
								>
									<TextField
										fullWidth
										size='small'
										label={<FormattedMessage id="rate" />}
										name="aliquota"
										required
										onChange={handleChange}
										value={servizio.aliquota}
									/>
								</Grid>
								<Grid
									item
									lg={4}
									md={4}
									xs={12}
									sx={{ display: 'flex', justifyContent: 'center' }}
								>
									<FormControlLabel
										control={<Checkbox checked={servizio.annuale} />}
										label={<FormattedMessage id="annual" />}
										name="annuale"
										labelPlacement="start"
										onChange={handleChange}
									/>
								</Grid>
								<Grid
									item
									lg={4}
									md={4}
									xs={12}
									sx={{ display: 'flex', justifyContent: 'center' }}
								>
									<FormControlLabel
										control={<Checkbox checked={servizio.ricorrente} />}
										label={<FormattedMessage id="recurrent" />}
										name="ricorrente"
										labelPlacement="start"
										onChange={handleChange}
									/>
								</Grid>
							</Grid>
						</CardContent>
					)
			}
			<Divider />
			<Grid container>
				<Grid item
					xs={6}
					md={6}
					lg={6}
				>
					{!isAdd && <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-start' }}>
						<Button
							color="error"
							type="submit"
							variant="contained"
							onClick={() => setDeleteServizioDialogOpen(true)}
							startIcon={<DeleteIcon />}
						>
							<FormattedMessage id="delete" />
						</Button>
					</Box>}
				</Grid>
				<Grid item
					xs={6}
					md={6}
					lg={6}
				>
					<Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							color="primary"
							type="submit"
							variant="contained"
							onClick={checkIfPriceOrVatRateChanged}
							startIcon={<SaveIcon />}
						>
							<FormattedMessage id="save" />
						</Button>
					</Box>
				</Grid>
			</Grid>
		</Card>
	)
};

export default ServizioUpsert;
